import {AfterViewInit, Component, ElementRef, Inject, Input, Renderer2, ViewChild} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.scss']
})
export class HrComponent implements  AfterViewInit {
  @Input() data: any;
  @ViewChild('psJobWidget', {read: ElementRef }) private psJobWidget: ElementRef;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit(): void {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = this.data.jsSRC;
    s.text = '';
    s.id = '';
    this.renderer2.appendChild(this.psJobWidget.nativeElement, s);
  }
}
