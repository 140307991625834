import { Component, Input, OnInit } from '@angular/core';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { CollectionItem, Tiles } from '@scoop-lli/collections/lib/collections.model';
import { Content } from '../../models/limitless-interaction.model';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  @Input() parentId: any;
  @Input() contextCategories: number[] = [];
  @Input() primaryCategoryId: string;
  @Input() data: any;

  recData: any;

  items: CollectionItem[];
  tiles: Tiles;

  constructor(private recommenderService: LimitlessInteractionService) { }

  async ngOnInit(): Promise<void> {

    this.recData = await this.recommenderService
      .loadRecommender2(this.data.tileItems, this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
    console.log('data', this.data);
    console.log('recData', this.recData);
    this.tiles = this.data.tileItems;
    console.log('tiles', this.tiles);
    this.items = this.recData.map((item: Content) => ({
      title: item.title,
      description: item.description,
      icon: {url: '', alt: ''},
      image: {url: item.coverThumbnailLarge, alt: item.imageAlt},
      publishDate: item.publishDate,
      url: item.url,
      buttons: [{
        text: 'Learn More',
        colorType: 'dark',
        url: item.url,
        target: '_self'
      }],
      socialMedia: {
        tag: item.tag,
        isFaceBook: true,
        isLinkedIn: true,
        isTwitter: true,
        urlLink: item.url,
        isPopup: true,
        isOpen: false
      }
    } as CollectionItem)) as CollectionItem[];
  }
}
