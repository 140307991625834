import { Component, OnInit } from '@angular/core';
import {GeneralSettingsService} from '../../services/general-settings.service';
import {map} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';
import {CookieService} from 'ngx-cookie';
import {Observable} from 'rxjs';
import {Cookies} from '../../models/general-settings.model';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  animations: [
    trigger('cookieAnimation', [
      transition(':enter', [
        style({ height: 0 }),
        animate('200ms', style({ height: '100px' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ height: 0 }))
      ])
    ])
  ]
})
export class CookieBannerComponent implements OnInit {
  cookieValue: string;

  $data = this.generalSettingsService.$data.pipe(
    map(item => item.data.cookies),
  ) as Observable<Cookies>;

  constructor(
    private generalSettingsService: GeneralSettingsService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.cookieValue = this.cookieService.get('cookies');
  }

  handleGotIt(): void {
    this.cookieService.put('cookies', 'true');
    this.cookieValue = 'true';
  }
}
