import {Component, Input, OnInit} from '@angular/core';
import {LimitlessInteractionService} from '../../services/limitless-interaction.service';
import {Category, CategoryName, Content, RecommendationOptions} from '../../models/limitless-interaction.model';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  @Input() data: any;
  @Input() contextCategories: number[] = [];
  @Input() parentId: any;
  @Input() primaryCategoryId: string;
  label = 'More Stories';
  counter = 0;
  categoriesId: string[];
  categories: Category[];
  categoriesNames: CategoryName[];
  isMultiCat = false;
  selectId = '0';
  contentsAll: Content[] = [];
  contentsToSow: Content[] = [];
  loading = false;
  showEMail = false;

  constructor( private recommenderService: LimitlessInteractionService ) { }

  ngOnInit(): void {
    this.categoriesId = this.data.recommender.categories.length
      && this.data.recommender?.categories?.map((item: number) => item + '');
    this.categoriesNames = this.data.recommender?.categoriesName?.map((item: Category) => item );
    this.isMultiCategory();
    this.selectFilter('0');
  }

  selectFilter(catID: string): void {
    catID = catID + '';
    this.selectId = catID;
    if (catID === '0') {
      this.categoriesId = this.data.recommender?.categories?.map((item: number) => item + '');
      this.getContacts(this.categoriesId, this.contextCategories);
      // this.contents$ = this.recData$;
    } else {
      this.getContacts([this.selectId], this.contextCategories);
    }
  }

  getContacts(ids: string[], contextContentIds: number[]): any {
    this.contentsAll = [];
    this.contentsToSow = [];
    this.loading = true;
    if (this.data.recommender.staticContents?.length) {
      this.contentsAll =  this.recommenderService.
      sortBy(this.recommenderService.formatStaticCategories(this.data.recommender.staticContents), this.data.recommender.sortBy);
      this.contentsToSow = this.contentsAll.slice(0, +this.data.contentsPerPage);
    }
    if (this.data.recommender.recommenderType === 'Manual Only') {
      this.loading = false;
      return ;
    }
    this.categoriesNames = this.data.recommender?.categoriesName?.map((item: Category) => item);
    const backupCategoriesId: string[] = this.data.recommender?.backupCategories &&
      this.data.recommender?.backupCategories?.map((item: number) => item + '');
    const size = this.data.recommender.size;
    let excludedContents: any[] = [];
    if (this.data.recommender.excludedItems) {
      excludedContents = excludedContents.concat(...this.data.recommender.excludedItems.split(','));
    }
    const options: RecommendationOptions = {
      categories: ids || contextContentIds,
      algorithmType: this.data.recommender.algorithmType,
      excludedContents,
      size,
    };
    this.recommenderService.getRecommendation(options).subscribe((data: Content[]) => {
      this.loading = false;

      this.contentsAll = this.contentsAll.concat(this.recommenderService.sortBy(data, this.data.recommender.sortBy));
      this.contentsToSow = this.contentsAll.slice(0, +this.data.contentsPerPage);

      if (size && this.contentsAll < size && (backupCategoriesId || this.contextCategories.length)) {
        const options2: RecommendationOptions = {
          categories: backupCategoriesId || contextContentIds,
          algorithmType: this.data.recommender.algorithmType,
          excludedContents: this.data.recommender.excludedItems.split(','),
          size: (size - this.contentsAll.length).toString()
        };
        this.recommenderService.getRecommendation(options2).subscribe(data2 => {
          const contentsBackup = this.recommenderService.filterRecommender(data2);
          this.contentsAll =  this.recommenderService.sortBy(this.contentsAll.concat(contentsBackup), this.data.recommender.sortBy);
          this.contentsToSow = this.contentsAll.slice(0, +this.data.contentsPerPage);
        });
      }
    });
  }

  showMore(event: any): any {
    this.contentsToSow = this.contentsToSow.concat(
      this.contentsAll.slice(this.contentsToSow.length,  this.contentsToSow.length + +this.data.contentsPerPage));
  }

  setCounter( i: number ): boolean {
    this.counter = i;
    return false;
  }

  getCounter(): number {
    return this.counter + 1;
  }

  private isMultiCategory(): void {
    if (this.categoriesId.length > 1) {
      this.isMultiCat = true;
    }
  }

  toData(date: string): Date {
    const newDate = new Date(date.replace(/-/g, '/'));
    return newDate;
  }
}

