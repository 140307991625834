import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private restService: RestService,
              private http: HttpClient,
              private transferState: TransferState,
              @Inject(PLATFORM_ID) private platformId: object
              ) {
  }


  $data = new BehaviorSubject<any>(null);

  $isHeaderTransparent = new BehaviorSubject<boolean>(false);

  $isFocus = new Subject();


  async getHomePage(path: string): Promise<any> {
    const PAGE_KEY = makeStateKey<any>('pageKey-' + path);
    if (this.transferState.hasKey(PAGE_KEY)) {
      const data = this.transferState.get(PAGE_KEY, null);
      this.transferState.remove(PAGE_KEY);
      this.$data.next(data);
      return data;
    } else {
      let data;
      if (path.startsWith('channel/')) {
        data = await this.http.get(`${environment.wpJson}${path}`).toPromise();
      } else {
         data = await this.http.get(`${environment.pageUrl}${path}`).toPromise();
      }
      if (isPlatformServer(this.platformId)) {
        this.transferState.set(PAGE_KEY, data);
      }
      this.$data.next(data);
      return data;
    }
  }
}
