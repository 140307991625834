import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'src/app/shared/sliders/carousel/carousel.component';
import { IconTextGalleryModel } from './icon-text-gallery.model';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-icon-text-gallery',
  templateUrl: './icon-text-gallery.component.html',
  styleUrls: ['./icon-text-gallery.component.scss']
})
export class IconTextGalleryComponent implements OnInit {
  @Input() data: IconTextGalleryModel;
  @ViewChild('slider') slider: CarouselComponent;
  _slideConfig: any = {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  
  handleNavigate(direction: string): void {
    if (direction === 'NEXT') {
      this.slider.next();
    }
    if (direction === 'PREV') {
      this.slider.prev();
    }
  }
  transformYourHtml(htmlTextWithStyle: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
  
}
