import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeChannelComponent } from '../../components/subscriptions/subscribe-channel/subscribe-channel.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ShareComponent } from '../../shared/share/share.component';
import { IconsModule } from '../../shared/icons/icons.module';
import { PopperDirective } from '../../shared/directives/popper.directive';
import { ChannelListComponent } from '../../components/channel-list/channel-list.component';
import { ButtonsModule } from '../../shared/buttons/buttons.module';
import { RouterModule } from '@angular/router';
import { VideoPlayComponent } from '../../shared/buttons/video-play/video-play.component';
import { DndDirective } from '../../shared/directives/dnd.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadImagePopupComponent } from '../../components/upload-image-popup/upload-image-popup.component';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { SubscribeBtnComponent } from '../../components/subscriptions/subscribe-channel/subscribe-btn/subscribe-btn.component';


@NgModule({
  declarations: [
    SubscribeChannelComponent,
    ShareComponent,
    PopperDirective,
    ChannelListComponent,
    VideoPlayComponent,
    DndDirective,
    UploadImagePopupComponent,
    FileSizePipe,
    SubscribeBtnComponent,
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    IconsModule,
    ButtonsModule,
    RouterModule,
    ImageCropperModule,
  ],
  exports: [
    SubscribeChannelComponent,
    LazyLoadImageModule,
    IconsModule,
    ShareComponent,
    PopperDirective,
    ChannelListComponent,
    VideoPlayComponent,
    DndDirective,
    UploadImagePopupComponent,
    FileSizePipe,
    SubscribeBtnComponent,
  ],
})
export class ComponentsModule { }
