import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first, shareReplay, tap } from 'rxjs/operators';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  constructor(private restService: RestService, private http: HttpClient,
              private transferState: TransferState,
              @Inject(PLATFORM_ID) private platformId: object) { }

  $data = this.getGeneralSettings();
  $theme = new BehaviorSubject(null);
  getGeneralSettings(): Observable<any> {

    const PAGE_KEY = makeStateKey<any>('general-settings');

    if (this.transferState.hasKey(PAGE_KEY)) {
      const data = this.transferState.get(PAGE_KEY, null);
      return of(data);
    }

    return this.restService.get('general-settings').pipe(
      first(),
      tap(data => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(PAGE_KEY, data);
        }
      }),
      shareReplay(),
    );
  }
}

