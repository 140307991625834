<div class="modal fade" #forgotModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4>Forgot Password</h4>
        <form [formGroup]="registerForm" (ngSubmit)="handleForgot()">
          <div class="form-item">
            <label for="email">Email Address</label>
            <input #emailAddress id="email" placeholder="Email Address" type="text" formControlName="email">
            <div *ngIf="registerForm.controls.email.touched">
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.email">Email must be valid.</div>
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.required">Please enter your email address.</div>
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.auth">Couldn't find any user with that email address.</div>
            </div>
            <p *ngIf="success" class="alert alert-success border-0 mt-3">Reset password email was sent!</p>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Submit'"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
