import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {AuthService} from '../../../services/auth.service';

declare var bootstrap: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  registerForm: FormGroup;
  submitLoading = false;
  modal: any;
  success = false;
  @ViewChild('forgotModal', {static: false}) forgotModal: ElementRef;
  @ViewChild('emailAddress', {read: ElementRef}) emailAddress: ElementRef<HTMLInputElement>;
  constructor(private formBuilder: FormBuilder, @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: object,  private authService: AuthService) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.modal = new bootstrap.Modal(this.forgotModal.nativeElement);
    this.forgotModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.registerForm.reset();
      this.success = false;
    });
  }

  public open(): any {
    this.registerForm.reset();
    this.success = false;
    this.modal.show();
  }


  async handleForgot(): Promise<void> {
    if (!this.registerForm.valid) {}
    this.submitLoading = true;
    try {
     const res = await this.authService.forgotPassword(this.registerForm.value);
     this.success = true;
     this.registerForm.reset();
    } catch (e) {
      this.registerForm.controls.email.setErrors({auth: e.error.message});
    } finally {
      this.submitLoading = false;
    }
  }
}
