
<div class="live-search-wrapper" (touchstart)="blurThis()">
  <p *ngIf="loading">Searching...</p>
  <div class="category-recommendation">
    <div class="category-item"  *ngFor="let category of categories" [ngClass]="(liveSearchAllResults.selectedId == category.externalId) ? 'active' : ''">
      <img src="/assets/atoms-icons-category.png" alt="">
      <p (click)="handleCatClick(category.title)">{{category.title}}</p>
    </div>
  </div>
  <div>
    <div class="content-item" *ngFor="let content of contents" [ngClass]="(liveSearchAllResults.selectedId == content.externalId) ? 'active' : ''">
      <a [routerLink]="'../' + content.url">
        <img *ngIf="content.type!.includes('page'); else showChannel"   [src]="content.coverThumbnailSmall ? content.coverThumbnailSmall : content.thumbnail" [alt]="content.imageAlt">
        <ng-template #showChannel >
          <img class="channel-image"
               srcset="{{content.roundImageRetinaSmall}} 2x"
               src="{{content.roundImageSmall}}"
               [alt]="content.imageAlt" >
        </ng-template>
      </a>
      <div [ngClass]="content.tag.includes('Channel')?'d-flex align-items-center':'' ">
        <a [routerLink]="'../' + content.url">
          <h5 class="search-result-title">{{content.title}}</h5>
        </a>
        <p class="small" [ngClass]="content.tag.includes('Channel')?'channel-border':'' ">{{content.tag}}</p>
      </div>
    </div>
  </div>
</div>
