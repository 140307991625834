import { Component, OnInit } from '@angular/core';
import {Image} from '../../models/image.model';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss']
})
export class StickerComponent implements OnInit {
  data: {image: Image, position: string};
  constructor() { }

  ngOnInit(): void {
  }

}
