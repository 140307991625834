import { Component, HostListener, Input, OnInit, ViewChild,  } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { Page } from '../../models/Page.model';
import Player from '@vimeo/player';
import { ViewportScroller  } from '@angular/common';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  animations: [
    trigger('sliderAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class HeroComponent implements OnInit{
  @Input() data: any;
  heroSlider: {post: Page, descriptionText: string, buttonOne: any, buttonTwo: any, linkText: string, bgVimeoId: string, fgVimeoId: string}[];
  currentSlide = 0;
  animate = true;
  testIDVideo = '595151122';
  private player: any;
  private playerFg: any;
  private isFullScreen = false;

  @ViewChild('slide') slide: CarouselComponent;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    lazyLoad: 'ondemand',
  };

  constructor(
    private viewportScroller: ViewportScroller,
  ) { }

  ngOnInit(): void {
    this.heroSlider = this.data.heroSlider;
  }

  @HostListener('fullscreenchange')
  @HostListener('webkitfullscreenchange')
  @HostListener('mozfullscreenchange')
  @HostListener('MSFullscreenChange')
  screenChange(): void {
    if ( this.isFullScreen ) {
      this.playerFg.play();
      if (this.player !== undefined) {
        this.player.pause();
        this.player.setVolume(0);
      }
      this.playerFg.element.style.visibility = 'visible';
      this.isFullScreen = false;
    } else {
      this.playerFg.pause();
      if (this.player !== undefined) {
        this.player.play();
        this.player.setVolume(0);
      }
      this.playerFg.element.style.visibility = 'hidden';
    }
  }

  initPlayer(): any {
    this.player = new Player('hero-bg-video-0');
  }

  playCurrentBgVideo(i: number): any {
    if (this.player) {
      this.player.pause();
    }
    if ( this.heroSlider[i].bgVimeoId ) {
      this.player = new Player('hero-bg-video-' + i);
      this.player.play();
      this.player.setVolume(0);
    }
  }

  fullscreenVideoItem(item: number): void {
    this.playerFg = new Player('hero-fg-video-' + item);
    this.playerFg.requestFullscreen();
    this.isFullScreen = true;
  }

  fullscreenVideoItemImg(item: number): void {
    this.playerFg = new Player('hero-fg-img-video-' + item);
    this.playerFg.requestFullscreen();
    this.isFullScreen = true;
  }

  onNextClick(): void {
    this.slide.next();
  }
  onPrevClick(): void {
    this.slide.prev();
  }

  handleChange(e: any): any {
    this.currentSlide = e.currentSlide;
    this.playCurrentBgVideo(this.currentSlide);
  }

  getIndicatorPosition(): number {
    return this.currentSlide  * (100 / this.heroSlider.length);
  }

  isVideo(item: any): boolean {
    if ( !item.bgVimeoId || item.bgVimeoId === 0 ) {
      return false;
    } else {
      return true;
    }
  }

  isFgVideo(item: any): boolean {
    if ( !item.fgVimeoId || item.fgVimeoId === 0 ) {
      return false;
    } else {
      return true;
    }
  }

  getVimeoBgVideoSRC(item: number, id: string): string {
    if (item === 0) {
      return id + '?background=1&loop=1&muted=1';
    } else {
      // removed 'autoplay=0' because mr Ksela wanted no delay
      return id + '?background=1&loop=1&';
    }
  }

  getVimeoFgVideoSRC(id: string): string {
      return id + '?&controls=1&muted=0';
  }
  public onClick(elementId: string): void { 
    const id = elementId.substring(1);
    this.viewportScroller.scrollToAnchor(id);
  }
}
