import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { HomepageService } from '../../services/homepage.service';
import { HighlightOneSectionComponent } from '../../components/highlight-one-section/highlight-section.component';
import { tap } from 'rxjs/operators';
import { CategoryRecommenderComponent } from '../../components/category-recommender/category-recommender.component';
import { JobsSectionComponent } from '../../components/jobs-section/jobs-section.component';
import { NewsSectionComponent } from '../../components/news-section/news-section.component';
import { HeroComponent } from '../../components/hero/hero.component';
import { TestimonialSectionComponent } from '../../components/testimonial-section/testimonial-section.component';
import { HighlightTwoSectionComponent } from '../../components/highlight-two-section/highlight-two-section.component';
import { PreviewRecommenderComponent } from '../../components/preview-recommender/preview-recommender.component';
import { RecommenderSectionComponent } from '../../components/recommender-section/recommender-section.component';
import { QuoteSliderSectionComponent } from '../../components/quote-slider-section/quote-slider-section.component';
import { HeroImageComponent } from '../../components/hero-image/hero-image.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GalleryComponent } from '../../components/gallery/gallery.component';
import { TitleTextQuoteComponent } from '../../components/text/title-text-quote/title-text-quote.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ColumnSubtitleTextComponent } from '../../components/text/column-subtitle-text/column-subtitle-text.component';
import { GalleryRecommenderSectionComponent } from '../../components/gallery-recommender-section/gallery-recommender-section.component';
import { GalleryQuoteComponent } from '../../components/gallery-quote/gallery-quote.component';
import { ImageQuoteTextComponent } from '../../components/text/image-quote-text/image-quote-text.component';
import { ImageOnlyComponent } from '../../components/text/image-only/image-only.component';
import { LogoGridSectionComponent } from '../../components/logo-grid-section/logo-grid-section.component';
import { GridComponent } from '../../components/grid/grid.component';
import { TextComponent } from '../../components/text/text/text.component';
import { SearchResultsComponent } from '../../components/search-results/search-results.component';
import { GetInTouchSectionComponent } from '../../components/get-in-touch-section/get-in-touch-section.component';
import { ColumnListSectionComponent } from '../../components/column-list-section/column-list-section.component';
import { Meta, Title } from '@angular/platform-browser';
import { NewMarketingLandscapeComponent } from '../../components/new-marketing-landscape/new-marketing-landscape.component';
import { IframeComponent } from '../../components/iframe/iframe.component';
import { HeroSliderComponent } from '../../components/hero-slider/hero-slider.component';
import { GridManualComponent } from '../../components/grid-manual/grid-manual.component';
import { HeadlineComponent } from '../../components/text/headline/headline.component';
import { HrComponent } from '../../components/hr/hr.component';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { ImageTitleTextComponent } from '../../components/text/image-title-text/image-title-text.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { AwardsComponent } from '../../components/awards/awards.component';
import { AwardAddOnComponent } from 'src/app/components/award-add-on/award-add-on.component';
import { ModuleGridComponent } from '../../components/module-grid/module-grid.component';
import { environment } from '../../../environments/environment';
import { Hotspot2dComponent } from '../../components/rooms/hotspot2D/hotspot2d.component';
import { VimeoVideComponent } from '../../components/vimeo-vide/vimeo-vide.component';
import { PolygonComponent } from '../../components/rooms/polygon/polygon.component';
import { SubscribeChannelComponent } from '../../components/subscriptions/subscribe-channel/subscribe-channel.component';
import { MyHistoryOptions } from '../../models/limitless-interaction.model';
import { MyHistory } from '../../models/my-history';
import {Hotspot360Component} from "../../components/rooms/hotspot360/hotspot360.component";
import { ContactComponent } from '../../components/contact/contact.component';
import { ContactInfoComponent } from '../../components/contact-info/contact-info.component';
import { ChannelsSubscribeComponent } from '../../components/subscriptions/channels-subscribe/channels-subscribe.component';
import { MyHistoryComponent } from '../../components/my-history/my-history.component';
import { CollectionComponent } from '../../components/collection/collection.component';
import {PicturesGalleryComponent} from '../../components/pictures-gallery/pictures-gallery.component';
import { IconTextGalleryComponent } from 'src/app/components/icon-text-gallery/icon-text-gallery.component';
import {AccordionComponent} from '../../components/accordion/accordion.component';
import {StickerComponent} from '../../components/sticker/sticker.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  @ViewChild('dynamic', {read: ViewContainerRef }) private viewRef: ViewContainerRef;
  private isSetSubscription = false;
  private isHistorySet = false;
  private isNotHistory = true;
  constructor(private homePageService: HomepageService,
              private cfr: ComponentFactoryResolver,
              private route: ActivatedRoute,
              private router: Router,
              private loadingBar: LoadingBarService,
              private metaService: Meta,
              private titleService: Title,
              private limitlessInteraction: LimitlessInteractionService,
              @Inject(PLATFORM_ID) private platformId: object,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
  ) {
  }

   async ngOnInit(): Promise<void> {
    await this.getPage();
    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        this.loadingBar.start();
        await this.getPage();
        this.homePageService.$isFocus.next(false);
      }
    });
  }

  async getPage(): Promise<void> {
    const route = this.router.url.substring(1).split('?')[0]; // replace(/\\|\//g, '').split('?')[0];
    try {
      await this.homePageService.getHomePage(route || 'home');
    } catch (e) {
      this.router.navigate(['/not-found']).then(() => {
        if (!isPlatformServer(this.platformId)) {
          // location.reload();
        }
      });
    }
    this.homePageService.$data.pipe(
      tap((data) => {
        this.limitlessInteraction.recommenderSource.next([]);
        if (!data) {return; }
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:site_name"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:locale"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('property="og:image:height"');
        this.metaService.removeTag('property="og:image:width"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="canonical"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:image"');
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('name="robots"');
        this.metaService.removeTag('property="article:modified_time"');

        const meta = data.data.head.json;
        const pageUrl = environment.baseUrl + this.router.url;

        const metaTitleCapitalized = meta.og_title.replace('amp;', '').charAt(0).toUpperCase() + meta.og_title.replace('amp;', '').slice(1);
        this.titleService.setTitle(metaTitleCapitalized);
        this.metaService.addTags([
          { name: 'description', content: meta.description || data.data.preferences.description},
          { name: 'robots', content: `
              ${meta.robots.index},
              ${meta.robots.follow},
              ${meta.robots['max-snippet']},
              ${meta.robots['max-image-preview']},
              ${meta.robots['max-video-preview']}`,
          },
          {property: 'canonical', content: meta.canonical || ''},
          {property: 'og:locale', content: meta.og_locale || ''},
          {property: 'og:type', content: meta.og_type || ''},
          {property: 'og:title', content: meta.og_title.replace('amp;', '') || ''},
          {property: 'og:description', content: meta.og_description || data.data.preferences.description},
          {property: 'og:url', content: pageUrl || ''},
          {property: 'og:site_name', content: meta.og_site_name.replace('amp;', '') || ''},
          {property: 'article:modified_time', content: meta.article_modified_time  || ''},
          {name: 'twitter:card', content: meta.twitter_card || ''},
          {name: 'twitter:image', content: meta.twitter_image  || ''},
          meta?.og_image?.[0]  ? {property: 'og:image', content: meta?.og_image[0].url} : {},
          meta?.og_image?.[0] ? {property: 'og:image:width', content: meta?.og_image[0].width} : {},
          meta?.og_image?.[0] ? {property: 'og:image:height', content: meta?.og_image[0].height} : {}
        ]);

      }),
      tap((data) => {
        this.viewRef.clear();
        this.loadingBar.complete();
        this.homePageService.$isHeaderTransparent.next(false);
      })
    )
      .subscribe((res) => {
        if (!res?.data) {
          return;
        }
        /* send page data to backend
      check if user logini
       */
        const pageTitle = res.data.preferences.title;
        if (pageTitle === 'Not Found' || pageTitle === 'Home')  {
          this.isNotHistory = true;
        }
        if (!this.isHistorySet && !this.isNotHistory){

          this.myHistory(res, route);
          this.isHistorySet = true;
        }
        this.isNotHistory = false;
        /* End MyHistory */
        /* isSetSubscription set subscription for the page */
        if ( res.data.preferences.channel !== undefined && res.data.preferences.channel  ) {
          this.isSetSubscription = true;
       }
        const { components, categories, id, primaryId } = res.data;
        if (route === 'search') {
          const compRef = this.cfr.resolveComponentFactory(SearchResultsComponent);
          const dynamicComponent =  this.viewRef.createComponent(compRef) as ComponentRef<any>;
          dynamicComponent.instance.data = components;
        }
        if (!components) { return; }
        components.forEach((item: any, index: number) => {
          // if (index === 0 && (item.componentName === 'hero' ||
          //   item.componentName === 'hotspot2d' ||
          //   item.componentName === 'hotspot360' ||
          //   item.componentName === 'polygon')) {
          //     this.homePageService.$isHeaderTransparent.next(true);
          // }
          /* is not isSetSubscription and is for page set subscription*/
          if ( this.isSetSubscription && index === 0) {
            const componentRef = this.cfr.resolveComponentFactory(SubscribeChannelComponent);
            const dynamicComponent = this.viewRef.createComponent(componentRef) as ComponentRef<any>;
            this.isSetSubscription = false;
            // dynamicComponent.instance.data = item;
          }
          const component = this.getComponent(item.componentName);
          if (component) {
            const componentRef = this.cfr.resolveComponentFactory(component);
            const dynamicComponent = this.viewRef.createComponent(componentRef) as ComponentRef<any>;
            dynamicComponent.instance.data = item;
            if (this.isRecommender(item.componentName)) {
              dynamicComponent.instance.contextCategories = categories;
              dynamicComponent.instance.parentId = id;
              dynamicComponent.instance.primaryCategoryId = primaryId;
            }
          }
        });
      }
    );
    this.isHistorySet = false;
  }

  private removeHtmlTags(s: string): string {
    return s.replace(/<[^>]*>/g, '');
  }

  getComponent(name: string): any {
    switch (name) {
      case 'categoryRecommender':
        return CategoryRecommenderComponent;
      case 'jobs':
        return JobsSectionComponent;
      case 'news':
        return NewsSectionComponent;
      case 'previewRecommender':
         return PreviewRecommenderComponent;
      case 'highlight-1':
      case 'staticHighlight-1':
        return HighlightOneSectionComponent;
      case 'highlight-2':
      case 'staticHighlight-2':
        return HighlightTwoSectionComponent;
      case 'hero':
        return HeroComponent;
      case 'fullscreenTestimonial':
        return TestimonialSectionComponent;
      case 'recommender':
        return RecommenderSectionComponent;
      case 'quoteSlider':
        return QuoteSliderSectionComponent;
      case 'heroImage':
      case 'heroImageRecommender':
        return HeroImageComponent;
      case 'gallery':
        return GalleryComponent;
      case 'text':
      case 'titleSubtitle':
        return TitleTextQuoteComponent;
      case 'columnWithSubtitle':
        return ColumnSubtitleTextComponent;
      case 'galleryRecommender':
        return GalleryRecommenderSectionComponent;
      case 'galleryQuote':
        return GalleryQuoteComponent;
      case 'imageTextQuote':
        return ImageQuoteTextComponent;
      case 'image':
          return ImageOnlyComponent;
      case 'logoGrid':
        return LogoGridSectionComponent;
      case 'gridRecommender':
        return GridComponent;
      case 'textCenter':
        return TextComponent;
      case 'contactUs':
        return GetInTouchSectionComponent;
      case 'columnList':
        return ColumnListSectionComponent;
      case 'marketingLandscape':
        return NewMarketingLandscapeComponent;
      case 'iframe':
        return IframeComponent;
      case 'heroSlider':
        return HeroSliderComponent;
      case 'grid':
        return GridManualComponent;
      case 'headline':
        return HeadlineComponent;
      case 'contactForm':
        return GetInTouchSectionComponent;
      case 'hr':
        return HrComponent;
      case 'imageTitleText':
        return ImageTitleTextComponent;
      case 'notFound':
        return NotFoundComponent;
      case 'awards':
        return AwardsComponent;
        case 'awardsAddOn':
        return AwardAddOnComponent;
      case 'moduleGrid':
        return ModuleGridComponent;
      case 'hotspot2d':
        return Hotspot2dComponent;
      case 'vimeoVideo':
        return VimeoVideComponent;
      case 'polygon':
        return PolygonComponent;
      case 'hotspot360':
        return Hotspot360Component;
      case 'subscriptionTeaser':
        return ChannelsSubscribeComponent;
      case 'contactData':
        return ContactComponent;
      case 'myHistory':
        return MyHistoryComponent;
      case 'collectionGgrid':
        return CollectionComponent;
      case 'contactInfo':
        return ContactInfoComponent;
      case 'pictureGallery':
        return PicturesGalleryComponent;
      case 'iconTextGallery':
        return IconTextGalleryComponent;
      case 'accordion':
        return AccordionComponent;
      case 'sticker':
        return StickerComponent;
      default:
        return null;
    }
  }

  isRecommender(componentName: any): boolean {
    switch (componentName) {
      case 'grid':
      case 'recommender':
      case 'categoryRecommender':
      case 'news':
      case 'previewRecommender':
      case 'galleryRecommender':
        return true;
    }
    return false;
  }

  myHistory(res: any, route: any): void {
    const options: MyHistoryOptions = {
      contents: res.data.id + ''
    };

    this.limitlessInteraction.saveMyHistory(options).subscribe((data: MyHistory) => {
      }, error => {
        console.error('There was an error', error);
      }
    );
  }
}
