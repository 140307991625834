import { Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { PREVIEW_RECOMMENDER } from '../../models/mocks/preview-recommender.mock';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { Content } from '../../models/limitless-interaction.model';
import { SeoUtils } from '../../utils/seo.utilis';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-recommender',
  templateUrl: './preview-recommender.component.html',
  styleUrls: ['./preview-recommender.component.scss']
})
export class PreviewRecommenderComponent implements OnInit {
  @Input()
  @HostBinding('class')
  elementClass = 'with-background';
  @ViewChild('slider') slider: CarouselComponent;
  @Input() contextCategories: number[] = [];
  @Input() parentId: any;
  @Input() primaryCategoryId: string;
  data: any;
  currentSlide = 0;
  recData: Content[] = [];
  previewData = PREVIEW_RECOMMENDER;
  isForMobileVisible = true;
  nextArrowDisable=true;
  prevArrowDisable=true;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    // loop:true,
    slidesToShow: 1.4,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  constructor( private recommenderService: LimitlessInteractionService,private sanitizer: DomSanitizer ) { }

  async ngOnInit(): Promise<void> {
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
    if ((this.data.doNotShowDescriptionInMobile !== null || true) &&  this.data.doNotShowDescriptionInMobile) {
      this.isForMobileVisible = false;
    }
  }


  handleNavigate(direction: string): void {
    if (direction === 'NEXT') {
      this.slider.next();
    }
    if (direction === 'PREV') {
      this.slider.prev();
    }
  }

  handleChange(e: any): void {
    this.currentSlide = e.currentSlide;
    if(this.recData[this.currentSlide]===this.recData[this.recData.length - 1] ){
      this.nextArrowDisable=false
    }
    else if(this.recData[this.currentSlide]!=this.recData[this.recData.length - 1] ){
      this.nextArrowDisable=true
    }
    if(this.recData[this.currentSlide]===this.recData[0] ){
      this.prevArrowDisable=false
    }
    else if(this.recData[this.currentSlide]!=this.recData[0] ){
      this.prevArrowDisable=true
    }
  }
  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }
  transformYourHtml(htmlTextWithStyle: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

}
