import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { AuthService } from '../../services/auth.service';

declare var bootstrap: any;

@Component({
  selector: 'app-upload-image-popup',
  templateUrl: './upload-image-popup.component.html',
  styleUrls: ['./upload-image-popup.component.scss']
})
export class UploadImagePopupComponent implements AfterViewInit {

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @ViewChild('uploadAvatar', {static: false}) uploadAvatarModel: ElementRef;
  @Output() avatar = new EventEmitter<string>();
  submitLoading = false;
  allowedExt = ['image/png', 'image/jpg', 'image/jpeg'];
  imageChangedEvent = '';
  croppedImage: string | null | undefined = '';
  isImageLoaded = false;
  minResError = false;
  fileTypeError = false;
  submitted = false;
  modal: any;
  uploadedFile: File | null = null;
  uploadFileFormat: string;
  curDate: Date;
  progress = 0;
  isSaveReady = false;
  constructor(
    private router: Router,
    private subscribeService: LimitlessInteractionService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    // do nothing
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.modal = new bootstrap.Modal(this.uploadAvatarModel.nativeElement);
  }

  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    this.uploadedFile = file;
    this.uploadFileFormat = this.checkFileFormat(file);
    this.curDate = new Date();
//    const file = event;
    this.fileTypeError = false;
    this.allowedExt.map(e => {
      if (e === file.type) {
        this.fileTypeError = false;
      }
    });

    const URL = window.URL || window.webkitURL;
    const image = new Image();
    image.src = URL.createObjectURL(file);

    if (this.fileTypeError) {
      this.minResError = false;
      return;
    }

    image.onload = (e: any) => {
      this.progress = 10;
      this.isSaveReady = false;
      const height = e.target.height;
      const width = e.target.width;
      const size = event.target.files[0].size;
      console.log('size');
      if (width > 100 && height > 100 && size < 10000000 ) {
        this.isImageLoaded = true;
        this.minResError = false;
        this.imageChangedEvent = event;
      } else {
      //  this.progress = 0;
        this.isSaveReady = false;
        this.isImageLoaded = false;
        this.minResError = true;
        this.imageChangedEvent = '';
      }
      console.log('onLoad');
    };
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage): void {
    this.progress = 50;
    console.log('imageLoaded');
  }

  cropperReady(): void {
    this.isSaveReady = true;
    this.progress = 100;
    console.log('READY');
  }

  loadImageFailed(): void {
    // show message
  }

  async goBack(): Promise<void> {
    // this.document.body.classList.remove('popup');
    await this.router.navigate(['dashboard']);
  }

  submit(): void {
    if (this.isImageLoaded) {
      this.authService.updateImage({
        title: this.uploadedFile?.name,
        type: this.uploadedFile?.type,
        // tslint:disable-next-line:no-non-null-assertion
        image: this.croppedImage!
      }).subscribe(async (data: any) => {
        this.avatar.emit(data);
        this.isImageLoaded = false;
        this.isSaveReady = false;
        this.croppedImage = null;
        this.uploadedFile = null;
      });
    }
  }

  // found it on THE internet, you know..
  dataURLtoFile(dataUrl: any,  filename: any): File {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  onWebcamCapture($event: any): void {
    this.prepareLiveImage($event);
  }
  prepareLiveImage($event: any): void {
    this.fileChangeEvent({ target: { files: [$event] }});
  }

  open(): any {
    this.submitted = false;
    this.modal.show();
  }
  deleteImag(): void {
    this.isImageLoaded = false;
    this.isSaveReady = false;
    this.croppedImage = null;
    this.uploadedFile = null;
  }

  checkFileFormat(file: File): string {
    switch (file.type) {
      case 'image/png':
        return '/assets/svg/Png.svg';
      case 'image/jpg':
        return '/assets/svg/Jpg.svg';
      case 'image/jpeg':
        return '/assets/svg/Jpeg.svg';
      default:
        return '';
    }
  }

}
