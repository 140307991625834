import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @Output() fileDropped = new EventEmitter<any>();

  constructor() {
    // nothing to see here
  }

  @HostListener('dragover', ['$event']) public onDragOver(event: any): void  {
    console.log('2222222222', event);
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('ondrop', ['$event']) public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.fileDropped.emit(event);
  }

}
