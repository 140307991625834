import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { LimitedChannels, SubscriptionPath } from '../../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../../services/limitless-interaction.service';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { SeoUtils } from '../../../utils/seo.utilis';
import { Router } from '@angular/router';
import { Channel } from '../../../models/channel';

@Component({
  selector: 'app-channels-subscribe',
  templateUrl: './channels-subscribe.component.html',
  styleUrls: ['./channels-subscribe.component.scss']
})
export class ChannelsSubscribeComponent implements OnInit {
  data: Channel[];
  limitedChannel: any;
  isShow = false;
  isShowError = false;
  route: string;
  maxChannels: string;
  maxContentsInChannel: string;
  isHomepage = false;
  isLogin = false;

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 1.05,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor(
    private subscribeService: LimitlessInteractionService,
    private authService: AuthService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.route = this.router.url.substring(1).split('?')[0];
    if (this.route === 'home' || this.route === '') {
      this.limitedChannel = this.data;
      console.log('lc: ', this.limitedChannel);
      this.maxChannels = this.limitedChannel.maxChannels;
      this.maxContentsInChannel =  this.limitedChannel.maxContentsInChannel;
      this.isHomepage = true;
    } else {
      this.maxChannels = '99999';
      this.maxContentsInChannel =  '99999';
    }
    this.authService.user.pipe(
      filter((user) => {
        return !!user;
      }),
    ).subscribe((user) => {
      const options: LimitedChannels = {
        channelReturnAmount: this.maxChannels,
        contentReturnAmount: this.maxContentsInChannel,
      };
      this.isLogin = true;
      this.subscribeService.getUserChannelsContents(options).subscribe(data => {
        if (data.status === 200) {
          this.data = data.contents;
          this.data.forEach((item, index) => {
            console.log('index', index);
            this.hasUserSubscribe(item);
            if (this.isHomepage){
              item.isAccordionOpen = index === 0;
            } else {
              item.isAccordionOpen = true;
            }
          });
        } else {
          this.isShowError = true;
        }
        this.isShow = true;
      });
    });
  }

  hasUserSubscribe(item: Channel): void {
    const options: SubscriptionPath = {
      channels: item.externalId
    };
    this.subscribeService.hasUserSubscription(options).subscribe(data => {
      if (data.contents !== null &&  data.contents.length > 0 &&
        (data.contents[0].metadata.notificationPreference === '0' || data.contents[0].metadata.notificationPreference === '1')) {
        if (data.contents[0].metadata.notificationPreference === '0') {
          item.isNotificationAll = false;
        } else if (data.contents[0].metadata.notificationPreference === '1') {
          item.isNotificationAll = true;
        }
      }
    });
  }

  openNotification(item: Channel): void {
    item.isOpen = !item.isOpen;
  }

  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }

  checkIsVideo(videoId: any): boolean {
    return videoId.length > 0;
  }

  closeSubMenu(item: Channel): void {
    item.isOpen = false;
  }

  openTab(item: Channel, event: any): void {
    if (this.isHomepage && !event.target.parentElement.classList.contains('buttonsCTA')) {
      const thisItem = item.isAccordionOpen;
      this.data.forEach((i) => {
        i.isAccordionOpen = false;
      });
      item.isAccordionOpen = !thisItem;
      console.log('event', item);
    }
  }
}
