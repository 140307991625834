import { Component, OnInit } from '@angular/core';
import {AccordionModel} from './accordion.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  data: AccordionModel;
  constructor() { }

  ngOnInit(): void {
  }
}
