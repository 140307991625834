import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {RegisterObj, User} from '../models/User.model';
import {RestService} from './rest.service';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<any>(null);

  token: string | null;

  constructor(private http: HttpClient, private restService: RestService) {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('user')) {
    this.user.next(JSON.parse(localStorage.getItem('user') as string));
    }
  }

  async login(data: {email: string, password: string}): Promise<void> {
    this.token = await this.http.post(environment.wpJson + 'auth/login', data).toPromise() as string;
    this.setToken(this.token);
    await this.loadUser();
  }

  get getToken(): string | null {
    return this.token;
  }

  async loadUser(): Promise<void> {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    } else {
      return ;
    }
    const user = await this.http.get<User>(environment.wpJson + 'user', {headers: this.getHeaders()}).toPromise();
    localStorage?.setItem('user', JSON.stringify(user));
    this.user.next(user);
  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  getStoredUser(): User | null {
    if (typeof localStorage !== 'undefined' && localStorage.getItem('user')) {
      const user = localStorage.getItem('user') as string;
      return JSON.parse(user);
    }
    return null;
  }

  setToken(data: string): void {
    // this.user.next(data);
    // this.token.next(data.token);
    if (localStorage) {
     localStorage.setItem('token', data);
    }
  }

  async updateProfile(data: {firstname: string, lastname: string, imageurl: string}): Promise<void> {
    const updatedUser = await this.http.put<User>(environment.wpJson + 'user/update', data, {headers: this.getHeaders()}).toPromise();
    localStorage.setItem('user', JSON.stringify(updatedUser));
    this.user.next(updatedUser);
  }

  async resetPassword(data: {userId: string, resetPasswordKey: string, newPassword: string}): Promise<any> {
    return await this.http.post(environment.wpJson + 'auth/reset', data).toPromise();
  }

  async changePassword(data: {email: string, password: string, newPassword: string}): Promise<any> {
    return await this.http.put(environment.wpJson + 'auth/change-password', data).toPromise();
  }

  async forgotPassword(data: {email: string}): Promise<any> {
    return await this.http.post(environment.wpJson + 'auth/forgot', data).toPromise();
  }


  async logout(): Promise<any> {
    console.log('LogOut');
    this.user.next(null);
    const res = await this.http.get(environment.wpJson + 'auth/logout', {headers: this.getHeaders()}).toPromise();
    this.token = null;
    if (localStorage) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    console.log('token: ', this.token);
    return res;
  }

  signup(data: RegisterObj): Promise<any> {
    return this.http.post(environment.wpJson + 'auth/signup', data).toPromise();
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `${this.getToken}`);
    return headers;
  }

  getHeadersFromData(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `${this.getToken}`);
    return headers;
  }


  getEmail(): any {
    let email;
    this.getUser().subscribe( (data: {email: string}) => {
      email = data.email;
    });
    return email;
  }

  updateImage<T>(data: {title: string | null | undefined, type: string | null | undefined,
    image: string | null | undefined}): Observable<T> {
    const reqUrl = `${environment.wpJson}avatar`;
    return this.http.post<T>(reqUrl, data,
      {
        headers: this.getHeadersFromData(),
        observe: 'response',
        withCredentials: true,
      }).pipe(
      map((res: any) => {
        // console.log(`%cRES POST ${endpoint}`, 'color: green;', res);
        return res.body;
      })
    );
  }
}
