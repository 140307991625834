import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Header } from '../../models/general-settings.model';
import { DOCUMENT, isPlatformBrowser, ViewportScroller  } from '@angular/common';
import { HomepageService } from '../../services/homepage.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  backgroundOpacity = 0;
  white = false;
  isHeadTrans = false;
  isFocused = false;
  showLinks=false
  menu = false;

  inputValue = new Subject();
  @ViewChild('input') input: ElementRef;
  liveSearchAllResults = {
    currentIndex: -1,
    selectedId: '',
    list: [] as any[],
  };

  constructor(
    private generalSettingsService: GeneralSettingsService,
    private homePageService: HomepageService,
    private cookieService: CookieService,
    private eRef: ElementRef,
    private router: Router,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) { }

  $data = this.generalSettingsService.$data.pipe(
    map(item => item.data.header),
  ) as Observable<Header>;
  
  ngOnInit(): void {
    if(window.location.pathname==="/"){
      this.showLinks=true
    }

    this.onScroll();
    this.homePageService.$isHeaderTransparent.subscribe(isHeadTrans => {
      this.isHeadTrans = isHeadTrans;
      if (!this.isHeadTrans) {
        this.backgroundOpacity = 1;
        this.white = true;
      } else {
        this.backgroundOpacity = 0;
        this.white = false;
      }
    });

    this.homePageService.$isFocus.subscribe(focus => {
      if (focus) {
        this.isFocused = true;
        this.white = true;
        this.backgroundOpacity = 1;
      } else {
        this.isFocused = false;
        if (this.input?.nativeElement) {
          this.input.nativeElement.blur();
          this.input.nativeElement.value = '';
        }
        this.onScroll();
      }
    });
  }

  handleFocus(value: string): void {
    this.homePageService.$isFocus.next(true);
  }

  handleCloseFocus(): void {
    this.homePageService.$isFocus.next(false);
  }

  handleMenu() {
    this.menu = !this.menu;
  }
    
  onChange(event: any): void {
    console.log('test')
    if(window.location.pathname==="/Privacy"){
      this.showLinks=false
    }
  }
  
  @HostListener('document:click', ['$event'])
  clickOut(event: any): any {
    if (this.eRef.nativeElement.contains(event.target)) {
      return;
    } else {
      this.homePageService.$isFocus.next(false);
    }
  }

  handleEnter(): Promise<boolean> | boolean {
    const {list, selectedId} = this.liveSearchAllResults;
    const selectedItem = list.find(item => item.externalId === selectedId);
    if (!this.input.nativeElement.value.length) {
      return false;
    }
    if (selectedId.length && selectedItem?.url) {
      return this.router.navigate([selectedItem.url]);
    }
    return this.router.navigate(['search'], {
      queryParams: {
        term: this.input.nativeElement.value,
      }, queryParamsHandling: 'merge'});
  }


  handleLiveResults(value: any): void {
    this.liveSearchAllResults.list = value;
  }

  handleOnDown(): void {
    const {list, currentIndex} = this.liveSearchAllResults;
    if (list[currentIndex + 1]) {
      this.liveSearchAllResults.currentIndex ++;
      const currentItem = list[this.liveSearchAllResults.currentIndex];
      this.liveSearchAllResults.selectedId = currentItem.externalId;
      this.input.nativeElement.value = list[this.liveSearchAllResults.currentIndex].title;
    } else {
      this.liveSearchAllResults.currentIndex = 0;
      this.liveSearchAllResults.selectedId = list[0].externalId;
      this.input.nativeElement.value = list[0].title;
    }
  }

  handleOnUp(): void {
    const { currentIndex, list} = this.liveSearchAllResults;
    if (list[currentIndex - 1]) {
      this.liveSearchAllResults.currentIndex --;
      const currentItem = list[this.liveSearchAllResults.currentIndex];
      this.liveSearchAllResults.selectedId = currentItem.externalId;
      this.input.nativeElement.value = list[this.liveSearchAllResults.currentIndex].title;
    } else {
      this.liveSearchAllResults.currentIndex = list.length - 1;
      this.liveSearchAllResults.selectedId = list[list.length - 1].externalId;
      this.input.nativeElement.value = list[list.length - 1].title;
    }
  }

  handleInput(value: string): void {
    this.inputValue.next(value);
    if (!this.liveSearchAllResults.selectedId.length) { return; }
    this.liveSearchAllResults = {
      currentIndex: -1,
      selectedId: '',
      list: [] as any[],
    };
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event?: Event): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.isHeadTrans || this.isFocused) {
      return;
    }
    this.backgroundOpacity = +(window.scrollY / window.innerHeight).toFixed(1);
    if (this.backgroundOpacity >= 0.6) {
      if (!this.white) {
        this.white = true;
      }
    }
    if (this.backgroundOpacity <= 0.5) {
      this.white = false;
    }
  }

  handleMagClick(): void {
    if (this.isFocused) {
      if (!this.input.nativeElement.value.length) {
        this.homePageService.$isFocus.next(false);
        return;
      }
      this.input.nativeElement.value = '';
      this.inputValue.next('');
      this.input.nativeElement.focus();
      this.liveSearchAllResults = {
        currentIndex: -1,
        selectedId: '',
        list: [] as any[],
      };
    } else {
      this.homePageService.$isFocus.next(true);
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 0);
    }
  }
  onClick(elementId: string): void { 
    const id = elementId.substring(1);
    console.log(id);
    this.viewportScroller.scrollToAnchor(id);
    this.menu=false
  }
}
function handleChange() {
  throw new Error('Function not implemented.');
}

