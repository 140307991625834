<div class="hero-slider-wrapper" [style]="{'--section-color': 'var(--theme-color)',  '--text-color': data.textColor}">
  <ng-container *ngIf="heroSlider?.length">
    <div class="hero-slider-indicator-wrapper" *ngIf="heroSlider.length > 1">
      <span class="small-p no-select">01</span>
      <div class="hero-slider-indicator">
        <div class="indicator-line" [style.top]="getIndicatorPosition() + '%'"  [style.height]=" 100/heroSlider.length + '%'"></div>
      </div>
      <span class="small-p no-select">0{{heroSlider.length}}</span>
    </div>
    <div class="col-12 carousel-wrapper">
      <app-carousel #slide dark (afterChange)="handleChange($event)" [slideConfig]="_slideConfig">
        <ng-template misCarouselItem *ngFor="let item of heroSlider; index as i">
          <div *ngIf="!isVideo(item); else videoBlock" class="slide-item" style="background-position: center;" >
            <img src="../../../assets/img/draw-first.png" class="draw-first" alt="img">
            <img src="../../../assets/img/draw-second.png" class="draw-second" alt="img">
            <div class="hero-text-wrapper">
              <div class="tag-wrapper">
                <span class="tag-item" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.post.preferences.tag"></span>
              </div>
              <h1  [innerHTML]="item.post.preferences.title"></h1>
              <div *ngIf="item.descriptionText" class="item-description" [innerHTML]="item.descriptionText"></div>
              <div class="align-items-center buttons d-flex">
                <div (click)="onClick(item.buttonOne.url)">
                  <app-underline-button 
                    class="first-b"
                    [text]="item.buttonOne.title"
                    [color]="data.textColor"
                  >
              </app-underline-button>
            </div>
            <div (click)="onClick(item.buttonTwo.url)">
                <app-underline-button
                  class=""
                  [link]="item.post.slug"
                  [text]="item.buttonTwo.title"
                  [color]="data.textColor"
                  [white]="true"
                >
                </app-underline-button>
              </div>
                <!-- <span class="d-flex align-items-center play-btn-container" *ngIf="isFgVideo(item)">
                  <span class="d-flex align-items-center large-p" (click)="fullscreenVideoItemImg( i )">
                    <span class="play-btn d-inline-block">
                      <app-video-play [isVideo]="true"></app-video-play>
                    </span>
                    Play Video
                  </span>
                </span> -->
              </div>
            </div>
            <!-- <div>
              <img class="lechner d-none d-md-block" src="/assets/img/Hero-Lechner.png" alt="">
              <img class="lechner lechner-mobile d-block d-md-none" src="/assets/img/hero-lechner2.png" alt="">
            </div> -->
            <!-- <iframe *ngIf="isFgVideo(item)"
                    id="hero-fg-img-video-{{i}}"
                    class="hero-fg-video position-absolute"
                    style="z-index: -99; visibility: hidden;"
                    [src]="getVimeoFgVideoSRC(item.fgVimeoId) | vimeoUrl"
                    data-cookieconsent="marketing"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
            >
            </iframe> -->
          </div>
          <ng-template #videoBlock >
            <div class="slide-item vimeo-wrapper">
              <div class="fg-layer"></div>
              <iframe
                id="hero-bg-video-{{i}}"
                class="hero-bg-video"
                [src]="getVimeoBgVideoSRC(i, item.bgVimeoId) | vimeoUrl"
                data-cookieconsent="marketing"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              >
              </iframe>
              <iframe *ngIf="isFgVideo(item)"
                  id="hero-fg-video-{{i}}"
                  class="hero-fg-video position-absolute"
                  style="z-index: -99; visibility: hidden;"
                  [src]="getVimeoFgVideoSRC(item.fgVimeoId) | vimeoUrl"
                  data-cookieconsent="marketing"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
              >
              </iframe>
              <div  class="  hero-text-wrapper position-absolute">
                <div class="tag-wrapper">
                  <span class="medium-p tag-item social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.post.preferences.tag"></span>
                  <div class="share-wrapper">
                    <app-share [linkToPage]="item.post.slug" ></app-share>
                  </div>
                </div>
                <a [routerLink]="item.post.slug"><h1 [ngClass]="item.descriptionText?'small-mar':''" [innerHTML]="item.post.preferences.title"></h1></a>
                <div *ngIf="item.descriptionText" class="item-description" [innerHTML]="item.descriptionText"></div>
                <div class="align-items-center d-flex">
                  <app-underline-button
                      class=""
                      [link]="item.post.slug"
                      [text]="item.linkText"
                      [color]="data.textColor"
                  >
                  </app-underline-button>
                  <span class="d-flex align-items-center play-btn-container" *ngIf="isFgVideo(item)">
                    <span class="d-flex align-items-center large-p" (click)="fullscreenVideoItem( i )">
                      <span class="play-btn d-inline-block">
                        <app-video-play [isVideo]="true"></app-video-play>
                      </span>
                      Play Video
                    </span>
                  </span>

                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </app-carousel>
    </div>
    <div class="slide-navigator" *ngIf="heroSlider.length > 1">
      <app-arrow-previous (click)="onPrevClick()" dark class="small-sc-arrow"></app-arrow-previous>
      <p (click)="onPrevClick()" class="prev-button no-select small-p">prev</p>
      <div class="slide-navigation-line"></div>
      <p (click)="onNextClick()" class="next-button no-select small-p">next</p>
      <app-arrow-next  (click)="onNextClick()"  dark class="small-sc-arrow"></app-arrow-next>
    </div>
  </ng-container>
</div>
