<div id="login-register">
  <div class="backdrop" *ngIf="showDropdown" (click)="closeSubMenu()"></div>
  <div *ngIf="!user">
    <button type="button" (click)="menuToggle()" class="login-register-btn d-flex justify-content-start align-items-center"  aria-labelledby="login-register" >
    <span class="svg-container d-flex justify-content-center align-items-center">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12a4.805 4.805 0 0 1-4.8-4.8c0-2.647 2.153-4.8 4.8-4.8s4.8 2.153 4.8 4.8S14.647 12 12 12m4.51.808A7.18 7.18 0 0 0 19.2 7.2 7.2 7.2 0 0 0 12 0a7.2 7.2 0 0 0-7.2 7.2 7.18 7.18 0 0 0 2.69 5.608C3.1 14.458 0 18.534 0 24h2.4c0-6 4.307-9.6 9.6-9.6 5.293 0 9.6 3.6 9.6 9.6H24c0-5.466-3.1-9.542-7.49-11.192" fill="#2A2A29" fill-rule="evenodd"/>
      </svg>
    </span>
      <span class="title-container login-register d-md-inline-block d-none">Login</span>
    </button>
    <div class="login-register-container" *ngIf="showDropdown" #dropdownRef>
      <p class="d-flex justify-content-center uppercase">Login</p>
      <p class="small text-center">Login to subscribe, collect and return to your favourite content</p>
      <p class="primary-sns-btn  d-flex justify-content-center" (click)="handleLoginModal()"  title="LogIn">
        Login
      </p>
      <div class="hr-sect">or</div>
      <p class="secondary-sns-btn buttonsCTA d-flex justify-content-center" (click)="handleSignUpModal()" title="Register">
        Register
      </p>
    </div>
  </div>

  <div *ngIf="user">
    <button type="button" (click)="menuToggle()" class="login-register-btn d-flex justify-content-start align-items-center" aria-labelledby="login-register" >
    <span class="svg-container d-flex justify-content-center align-items-center">
     <img class="user-img" *ngIf="user.profile_picture" alt="{{user.first_name}}" [src]="user.profile_picture">
       <svg *ngIf="!user.profile_picture" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12a4.805 4.805 0 0 1-4.8-4.8c0-2.647 2.153-4.8 4.8-4.8s4.8 2.153 4.8 4.8S14.647 12 12 12m4.51.808A7.18 7.18 0 0 0 19.2 7.2 7.2 7.2 0 0 0 12 0a7.2 7.2 0 0 0-7.2 7.2 7.18 7.18 0 0 0 2.69 5.608C3.1 14.458 0 18.534 0 24h2.4c0-6 4.307-9.6 9.6-9.6 5.293 0 9.6 3.6 9.6 9.6H24c0-5.466-3.1-9.542-7.49-11.192" fill="#2A2A29" fill-rule="evenodd"/>
       </svg>
    </span>
      <span class="title-container login-register d-none d-md-inline-block">Hi, {{user.first_name}}</span>
    </button>
    <div class="login-register-container user-is-login" *ngIf="showDropdown">
      <p class="uppercase">Hi, {{user.first_name}}</p>
      <ul class="list-group list-group-user-links">
        <li class="list-group-item">
          <a class="buttonsCTA buttonsCTA-mysubscriptions" [href]="'/subscriptions'" title="My Subscriptions" style="background: url('/assets/svg/subscriptions.svg') left center no-repeat">My Subscriptions</a>
        </li>
        <li class="list-group-item">
          <a class="buttonsCTA buttonsCTA-myhistory" [href]="'/my-history'" title="My History" style="background: url('/assets/svg/history.svg') left center no-repeat">My History</a>
        </li>
        <li class="list-group-item">
          <a class="buttonsCTA buttonsCTA-mydata" (click)="handleMyData()" title="My Data" style="background: url('/assets/svg/profile-pic.svg') left center no-repeat">My Data</a>
        </li>
        <li class="list-group-item logout">
          <a class="buttonsCTA buttonsCTA-logout" (click)="logout()" title="LogOut" style="background: url('/assets/svg/logOut.svg') left center no-repeat">LogOut</a>
        </li>
      </ul>
    </div>
  </div>


</div>
<app-login-modal #loginModal (switchToRegister)="switchToForgot()" (switch)="switchToRegister()"></app-login-modal>
<app-signup-modal #signupModal (switch)="switchToLogin()" ></app-signup-modal>
<app-forgot-password  #forgotModal></app-forgot-password>
<app-change-password  #changeModal></app-change-password>
<app-my-data #myDataModel></app-my-data>

