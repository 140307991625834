<div class="channel-list"  [style]="{ '--text-color': '#0a0a0a'}"  *ngIf="isDataAvailable">
  <div class="container">
    <div class="row">
      <h4>Channel Content</h4>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
      <div class="col item-elem position-relative"  *ngFor="let item of contentsToShow; index as i; " >
        <a href="{{item.url}}"  >
          <app-video-play [isVideo]="checkIsVideo(item.metadata.vimeoId)"></app-video-play>
          <img class="article-img"
               [defaultImage]="item.metadata.coverThumbnailSmall ? item.metadata.coverThumbnailSmall : item.metadata.thumbnail"
               [lazyLoad]="item.metadata.coverThumbnailLarge ? item.metadata.coverThumbnailLarge : item.metadata.mediumImg"  alt="{{ item.metadata.imageAlt }}">
        </a>
        <div class="under-container h-100 ">
          <div class="d-flex">
            <h6 class="medium-p tag-item d-inline-block social-title"   [innerHTML]="item.content[0].tags[0]"></h6>
            <div class="share-wrapper d-inline-block">
              <app-share [white]="false" [linkToPage]="item.url" ></app-share>
            </div>
          </div>
          <a href="{{item.url}}"  >
            <h5 class="item-title mobile2-leed">{{ item.content[0].title }}</h5>
          </a>
        </div>
      </div>
    </div>
    <div class="more" *ngIf="(contentsAll.length !== contentsToShow.length) && contentsAll.length > contentsPerPage" >
      <div class="row">
        <div class="item-counter  col d-flex justify-content-center">
          <p>{{ contentsToShow.length }} of {{ contentsAll.length  }}</p>
        </div>
      </div>
      <div class="row" *ngIf="12 > 9">
        <div class="col d-flex justify-content-center">
          <app-rectangle-button (onClick)="showMore($event)" [label]="'Show more'"></app-rectangle-button>
        </div>
      </div>
    </div>
  </div>
</div>
