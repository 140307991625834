import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import { PageRoute } from '../../models/page-route.model';
import { MailService } from '../../services/mail.service';
import { ToastComponent } from '../toast/toast.component';


@Component({
  selector: 'app-get-in-touch-section',
  templateUrl: './get-in-touch-section.component.html',
  styleUrls: ['./get-in-touch-section.component.scss']
})
export class GetInTouchSectionComponent implements OnInit {
  @Input() data: any;
  @Input()  email: string | boolean;
  focusElem: string;
  form: FormGroup;
  previewPageUrl: PageRoute;
  isValideMail = false;
  loading = false;
  token = '';
  @ViewChild(ToastComponent)
  toast: ToastComponent;

  constructor(
    private client: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private mailService: MailService
  ) {
    this.previewPageUrl  = this.router.getCurrentNavigation()?.extras.state!.data?.previewPageUrl;
  }

  ngOnInit(): void {
  }

  validateEmail(email: string): boolean {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isValideMail = regularExpression.test(String(email).toLowerCase());
    return this.isValideMail;
  }

  log(x: any): void {
    // console.log(x);
  }

  setFocus(name: string): void {
    this.focusElem = name;
  }

  async onSubmit(contactForm: NgForm): Promise<void> {
    if(!contactForm.valid){
      return ;
    }
    try {
      const token = await this.recaptchaV3Service.execute('submit').toPromise();
      const body = {...contactForm.value, token};
      this.loading = true;
      try {
        const data = await this.mailService.sendMail(body);
        this.loading = false;
        this.toast.text = data.message;
        this.toast.danger = data.status !== 200;
        this.toast.show();
        if ( data.status !== 200) {
          return;
        }
        contactForm.resetForm();
      } catch (e) {
        this.loading = false;
        this.toast.text = 'Serverfehler, versuchen Sie es später erneut';
        this.toast.danger = true;
        this.toast.show();
      }
    } catch (e) {
      alert(e)
    }
  }
}
