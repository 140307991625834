<!--<div class="modal fade" #uploadAvatar id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<div class="modal fade" #uploadAvatar  id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="back-to" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">My Data</span>
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <div class=" upload-image wrapper">
          <h4 class="text-center mb-3">Upload Image</h4>

          <div class="dropzone" appDnd (fileDropped)="fileChangeEvent($event)" *ngIf="!isImageLoaded">
            <div class="inner-border">
              <input type="file" #fileDropRef id="fileDropRef" accept=".jpg, .jpeg, .png" (change)="fileChangeEvent($event)" />
              <div class="info">
                <img src="assets/svg/file-upload.svg" alt="file upload" />
                <div class="fw-bold">Drag and drop here</div>
                <div class="or">or</div>
                <div class="fw-bold">Browse</div>
              </div>
            </div>
          </div>
          <div class="progress" *ngIf="progress>0 && progress < 100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" [style.width]="progress+'%'"></div>
          </div>
          <div class="cropper" *ngIf="!minResError && isImageLoaded" [ngClass]="isImageLoaded? 'visible':'hidden'" >
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
                [roundCropper]="true"
                [resizeToWidth]="300"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
          </div>

          <div class="row mb-4">
            <div class="col-12" *ngIf="!isImageLoaded">
              <span class="pb-0 mb-0 font-weight-bold" >File Types: jpg, jpeg, png</span><br>
              <span class="pb-0 mb-0 font-weight-bold" >Min. image resolution: 100x100px</span>
            </div>

            <div class="col-12 error d-flex " *ngIf="fileTypeError">
              <img class="align-self-center" src="assets/svg/warning.svg" alt="error" />
              <p class="error align-self-center">File type not allowed</p>
            </div>

            <div class="col-12 error d-flex" *ngIf="minResError">
              <img class="align-self-center" src="assets/svg/warning.svg" alt="error" />
              <span class="align-self-center" >Image resolution is too low or size is to big (>10MB). Please upload another image.</span>
            </div>

            <div class="col-12 d-flex justify-content-between file-info-container" *ngIf="uploadedFile! && !(minResError || fileTypeError)">
              <div class="first d-flex justify-content-center align-items-center">
               <img src="{{uploadFileFormat}}"  (click)="deleteImag()" alt="file"/>
               </div>
              <div class="middle ps-3 d-flex flex-column">
                <span class="paragraph ">{{uploadedFile.name}}</span>
                <span >{{curDate | date: 'dd MMM.yyyy' }} | {{uploadedFile.size | fileSize }}</span>
              </div>
              <div class="last d-flex justify-content-center align-items-center ms-auto">
                <img src="/assets/svg/x.svg" class="delete-image" (click)="deleteImag()" alt="delete file"/>
              </div>

            </div>

           <!-- <div class="col-12 preview" *ngIf="isImageLoaded">
              <p class="title mb-1">Preview</p>
              <span class="sub-title">This is how your profile image will appear on your User Dashboard, User Profile and on Matchmaking Grid Page.</span>
              <img [src]="croppedImage" class="round-preview" />
              <img [src]="croppedImage" class="square-preview" />
            </div>-->

            <div class="button-wrapper d-flex justify-content-center flex-column">
              <button class="sbm-btn" type="submit"  [ngClass]="{ 'disabled' : !isSaveReady}" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">
                <app-rectangle-button [loading]="submitLoading" dark [label]="'Save'" (click)="submit()"></app-rectangle-button>
              </button>
              <button data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal" >
                <app-rectangle-button  [label]="'Cancel'"></app-rectangle-button>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
