<div  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container marketing-landscape">
    <div class="text-center">
      <div class="rectangle dynamic" (click)="handleClick(data.firstRectangle)" [ngClass]="{active: selected?.title === data.firstRectangle.title}"  [style]="{'--active-color': data.firstRectangle.color}">
        <h5 [innerHTML]="data.firstRectangle.title"></h5>
        <h5 class="light-text" [innerText]="data.firstRectangle.subtitle"></h5>
      </div>
      <div class="rectangle dynamic" (click)="handleClick(data.secondRectangle)" [ngClass]="{active: selected?.title === data.secondRectangle.title}"  [style]="{'--active-color': data.secondRectangle.color}">
        <div class="small-square-wrapper left">
          <div class="square-small" *ngFor="let square of smallSquareThing"></div>
        </div>

        <h5 [innerHTML]="data.secondRectangle.title"></h5>
        <h5 class="light-text" [innerText]="data.secondRectangle.subtitle"></h5>

        <div class="small-square-wrapper right">
          <div class="square-small" *ngFor="let square of smallSquareThing"></div>
        </div>
      </div>
      <div class="dynamic-wrapper">
        <h5 class="dynamic-wrapper-title" [innerHTML]="data.largeRectangle.dynamicContents.title"></h5>
        <div  class="row mx-0 minus-row ni">
          <div class="col small-rectangle dynamic"
               *ngFor="let item of data.largeRectangle.dynamicContents.items"
               (click)="handleClick(item)"
               [ngClass]="{active: selected?.title === item.title}"
               [style]="{'--active-color': item.color}">
            <h5 class="light-text" [innerHTML]="item.title"></h5>
          </div>

        </div>
        <div class="row">
          <div class="col" *ngFor="let item of data.largeRectangle.dynamicContents.items" >
            <div class="arrow-wrapper">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 200.423 200.423" style="enable-background:new 0 0 200.423 200.423;" xml:space="preserve">
              <g>
                <polygon style="fill:#010002;" points="7.913,102.282 192.51,102.282 160.687,134.094 163.614,137.018 200.423,100.213
                  163.614,63.405 160.687,66.325 192.51,98.145 7.913,98.145 39.725,66.332 36.798,63.405 0,100.213 36.798,137.018 39.725,134.101
                  "/>
              </g>
            </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="static-wrapper" (click)="handleClick(data.largeRectangle.staticContents)" [ngClass]="{active: selected?.title === data.largeRectangle.staticContents.title}"  [style]="{'--active-color': data.largeRectangle.staticContents.color}">
        <div class="large-square-wrapper">
          <div class="square" *ngFor="let square of largeSquareThing"></div>
        </div>
        <h5 class="static-wrapper-title" [innerHTML]="data.largeRectangle.staticContents.title"></h5>
        <div class="row mx-0 minus-row dy">
          <div class="col-12 col-md small-rectangle" *ngFor="let item of data.largeRectangle.staticContents.items">
            <h5 class="light-text" [innerHTML]="item.title"></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #target class="target">
    <div *ngIf="selected?.content" class="selected-content">
      <div class="content-text-wrapper">
        <div class="content-text text-md-center">
          <h4 [innerHTML]="selected.title"></h4>
          <div class="_text" [innerHTML]="selected.content"></div>
        </div>
      </div>
      <img [lazyLoad]="selected.image.url" [defaultImage]="selected.image.sizes.medium" [alt]="selected.image.alt">
    </div>
  </div>

</div>
