import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MarketingLandscape } from '../../models/marketing-landscape.model';

@Component({
  selector: 'app-new-marketing-landscape',
  templateUrl: './new-marketing-landscape.component.html',
  styleUrls: ['./new-marketing-landscape.component.scss']
})
export class NewMarketingLandscapeComponent implements OnInit {
  @Input() data: MarketingLandscape;
  @ViewChild('target') target: ElementRef;
  smallSquareThing = new Array(10);
  largeSquareThing = new Array(120);
  selected: {
    title: string
    image: any
    content: string
  };

  constructor() { }

  ngOnInit(): void { }

  handleClick(selected: any): void {
    this.selected = {
      title: selected.title,
      content: selected.content,
      image: {
        alt: selected.image.alt || '',
        url: selected.image.url,
        sizes: {
          medium: selected.image.sizes.medium,
        }
      }
    };
    this.target.nativeElement.scrollIntoView();
  }
}
