import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CarouselItemDirective } from '../../directives/carousel-item.directive';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewInit, AfterContentInit {
  [x: string]: any;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ContentChildren(CarouselItemDirective) itemTemplates: QueryList<CarouselItemDirective>;
  @Input() slidesToShow: number;
  @Input() dark: string;
  @Input() isArrowContainer: boolean;
  @Input() position: SliderPositionArrows;
  @Output() breakpoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() beforeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() init: EventEmitter<any> = new EventEmitter<any>();
  public instance: any;
  public currentSlide: any;
  public currentPositionSlide: number;
  public allSlides: number;
  sliderPositionArrows = SliderPositionArrows;
  first = false;
  last = false;

  // tslint:disable-next-line
  private _slideConfig: any = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  @Input() set slideConfig(val: any) {
    if (val) {
      this._slideConfig = val;
    }
  }

  get slideConfig(): any {
    return this._slideConfig;
  }

  constructor( private cdr: ChangeDetectorRef ) { }

  ngOnInit(): void {
    if (typeof this.slidesToShow !== 'undefined') {
      this.slideConfig.slidesToShow = this.slidesToShow;
      this.slideConfig.slidesToScroll = this.slidesToShow;
    }
  }

  ngAfterContentInit(): void { }

  ngAfterViewInit(): void {
    this.cdr.markForCheck();
    this.slickModal.breakpoint.subscribe((b) => {
      const point = b.breakpoint;
      const responsive = this.slideConfig.responsive.find((r: any) => r.breakpoint === point);
      if (responsive) {
        this.slidesToShow = responsive.settings.slidesToShow;
      }
    });
  }

  onAfterChange(e: { event: any; slick: any; currentSlide: number; first: boolean; last: boolean }): void {
    this.first = e.first;
    this.last = e.last;
    const { slick, currentSlide } = e;
    const slidesToShow = slick.options.slidesToShow;
    this.allSlides = slick.slideCount;
    this.currentPositionSlide = currentSlide + 1;
    if (slidesToShow + currentSlide >= slick.slideCount) {
      this.last = true;
    }
    this.afterChange.emit(e);
  }

  slickInit(event: any): void {
    this.instance = event.slick;
    // this.instance.setDimensions();
    // this.cdr.markForCheck();
    // // this.init.emit(event);
    // this.instance.slickGoTo(0);
    setTimeout(() => {
      this.instance.setDimensions();
      this.init.emit(event);
      this.instance.setDimensions();
      this.cdr.markForCheck();
      this.instance.slickGoTo(0);
    }, 250);
  }

  public setDimensions(): void {
    this.instance.setDimensions();
  }

  public get templates(): CarouselItemDirective[] {
    return this.itemTemplates.toArray();
  }

  onBreakpoint(event: any): void {
    event.slick.setDimensions();
    this.instance.setDimensions();
    this.breakpoint.emit(event);
  }

  prev(): void {
    this.slickModal.slickPrev();
  }

  next(): void {
    this.slickModal.slickNext();
    this.cdr.markForCheck();
  }
}

export enum SliderPositionArrows {
  // first is desktop and second is mobile
  center = 'center',
  left = 'left',
  right = 'right',
}
