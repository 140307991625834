<div [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container px-md-0 _container" *ngIf="!isDynamic" [style]=" data.imageOptions === 'HIDE' ? {'padding-bottom': 0} : {}">
    <h6 class="social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.tag"></h6>
    <app-share class="share-button" [linkToPage]="pageLink()"></app-share>
    <h1 [innerHTML]="data.title" [style]=" data.imageOptions === 'HIDE' ? {'margin-bottom': 0} : {}"></h1>
    <div  *ngIf="!data.vimeoId"  class="image-wrapper {{data.imageOptions.toLocaleLowerCase()}}">
      <img class="img-{{data.imageOptions.toLocaleLowerCase()}}" [lazyLoad]="data.mainImage.url" [defaultImage]="data.mainImage.sizes.thumbnail"  [alt]="data.mainImage.alt">
    </div>
    <div  *ngIf="data.vimeoId"  class="row hero-video-wrapper px-2">
      <div class="col-12 col-md-8 hero-video-conntainer pe-md-0">
        <iframe
            id="hero-video"
            [src]="getVimeoVideo(data.vimeoId ) | vimeoUrl"
            data-cookieconsent="marketing"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
        >
        </iframe>
      </div >
      <div class="col-12 col-md-4 p-md-0">
        <div class="hero-video-embed h-100">
          <ng-component [outerHTML]="data.embedVimeoContent">
          </ng-component>
        </div>
      </div>
    </div>
  </div>

  <div class="container _container px-md-0" [ngClass]="{'isDynamic': isDynamic}" *ngIf="isDynamic">
    <h6 class="social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.tag"></h6>
    <app-share class="share-button"></app-share>
    <h1 [innerHTML]="data.title"></h1>
    <a [routerLink]="'../' + data.post?.slug" >
      <img class="_second" *ngIf="data.post" [defaultImage]="data.post.preferences.mainImage.sizes.thumbnail" [lazyLoad]="data.post.preferences.mainImage.sizes.large" [alt]="data.post?.preferences?.mainImage?.alt">
    </a>
    <h6 class="_second social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.post?.preferences?.tag"></h6>
    <app-share class="share-button" *ngIf="data.post?.slug" [linkToPage]="contentLink(data.post?.slug)" ></app-share>
    <div class="d-flex">
      <a  [routerLink]="'../' + data.post?.slug" >
        <h5 [innerHTML]="data.post?.preferences?.title"> </h5>
      </a>
    </div>
  </div>
</div>
