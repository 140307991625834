<div class="open-overflow" *ngIf="isOpen"  (click)="openNotification()" ></div>
<div class="subscribe-channel" [ngClass]="isChannel? 'channel-view': ''" >
  <div class="container-fluid p-0" *ngIf="mainImage! && mainImage.length >1">
    <div class="full-image-header"  [ngStyle]="{'background-image': 'url(' + mainImage + ')'}" >
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 " [ngClass]="isChannel?'col-md-8 ':''">
        <div class="px-2 px-md-0 image-container d-flex align-items-center" [ngClass]="isChannel?'flex-column flex-md-row':''">
          <a *ngIf="!isChannel && isLoad" [routerLink]=" '../' + data.contents[0].url" >
            <img srcset="{{data.contents[0].metadata.roundImageRetinaSmall}} 2x"
                 src="{{data.contents[0].metadata.roundImageSmall}}"
                 alt="{{data.contents[0].metadata.contentType}}"
                 loading="lazy"/>
          </a>
          <img *ngIf="isChannel"
               srcset="{{lazyLoadImg2}} 2x"
               src="{{lazyLoadImg}}"
               alt="{{ altImg }}"
               class="is-channel-round-img"
               loading="lazy"/>
          <div class="d-flex align-items-center">
            <h4 *ngIf="isChannel" class="channel-name ">{{title}}</h4>
            <a *ngIf="!isChannel && isLoad" [routerLink]=" '../' + data.contents[0].url" >
              <p *ngIf="!isChannel" class="channel-name d-inline-block">{{ data.contents[0].content[0].title }}</p>
            </a>
            <p class="channel-name small channel-border">Channel</p>
            <app-subscribe-btn [ngClass]="isChannel?'d-none ':'d-none d-md-inline-block'"
                               [isChannel]="isChannel"
                               [isOpen]="isOpen"
                               [isUserLoggedIn$]="isUserLoggedIn$"
                               [isSubscribe]="isSubscribe"
                               [isNotificationAll]="isNotificationAll"
                               (parentSubscribe)="subscribe()"
                               (parentOnSelect)="onSelect($event)"
                               (parentOpenNotification)="openNotification()"
            ></app-subscribe-btn>
          </div>
        </div>
      </div>
      <div class="col-12 px-md-0  position-relative right-col align-self-center" [ngClass]="isChannel?'col-md-4 justify-content-end':'d-md-none col-md-6'">
        <app-subscribe-btn
                           [isChannel]="isChannel"
                           [isOpen]="isOpen"
                           [isUserLoggedIn$]="isUserLoggedIn$"
                           [isSubscribe]="isSubscribe"
                           [isNotificationAll]="isNotificationAll"
                           (parentSubscribe)="subscribe()"
                           (parentOnSelect)="onSelect($event)"
                           (parentOpenNotification)="openNotification()"
        ></app-subscribe-btn>
      </div>
    </div>
    <div class="row channel-description" *ngIf="isChannel">
      <div class="col-12 col-md-12">
        <p>{{description}}</p>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="border-radius: 0;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subscribe the channel</h5>
        <button #unsubscribeClose type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        Do you want to subscribe to this channel?
        Sign up to subscribe to this channel.
      </div>
      <div class="modal-footer">
        <button (click)="openLogin()" type="button" class="primary-sns-btn col-9 col-md-4">LogIn</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="border-radius: 0;">
      <div class="modal-body">
        Unsubscribe from {{title}}?
      </div>
      <div class="modal-footer">
        <button type="button" class="primary-sns-selected-btn" style="padding-left: 2rem; padding-right: 2rem;" data-bs-dismiss="modal">Close</button>
        <button type="button" class="primary-sns-btn" (click)="subscribe()" style="padding-left: 2rem; padding-right: 2rem;" data-bs-dismiss="modal">Unsubscribe</button>
      </div>
    </div>
  </div>
</div>
