import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/User.model';
import {isPlatformBrowser} from '@angular/common';
import { UploadImagePopupComponent } from '../../upload-image-popup/upload-image-popup.component';

declare var bootstrap: any;

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent implements OnInit, AfterViewInit {

  @ViewChild('myDataModel', {static: false}) myDataModel: ElementRef;
  @ViewChild('firstName', {read: ElementRef}) firstName: ElementRef<HTMLInputElement>;
  @ViewChild(UploadImagePopupComponent) uploadAvatarModel: UploadImagePopupComponent;
  updateForm: FormGroup;
  submitLoading = false;
  submitted = false;
  error = false;
  errorMsg: string;
  modal: any;
  editPassword = false;
  showDropdown = false;
  avatar = 'assets/svg/profile-pic.svg';
  constructor(private formBuilder: FormBuilder, private authService: AuthService,  @Inject(PLATFORM_ID) private platformId: object) { }

  ngOnInit(): void {
    this.updateForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
    }, {validators: passwordMatchValidator});

    function passwordMatchValidator(g: FormGroup): any {
      if (!g.get('newPassword')?.value){
        return null;
      }
      // @ts-ignore
      return g.get('newPassword').value === g.get('newPasswordConfirmation').value
        ? null : {mismatch: true};
    }

    this.authService.getUser().subscribe((user: User) => {
      if (!user) {return; }
      this.updateForm.patchValue({
        firstname: user.first_name,
        lastname: user.last_name,
        email: user.email,
      });
      if (user.profile_picture !== undefined && user.profile_picture.length > 1 ) {
       this.avatar = user.profile_picture;
     }
    });
    this.updateForm.controls.email.disable();
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.modal = new bootstrap.Modal(this.myDataModel.nativeElement);
  }

  public open(): any {
    this.submitted = false;
    this.modal.show();
  }

  handleSwitch(): void {
    this.editPassword = !this.editPassword;
    if (this.editPassword) {
      this.updateForm.addControl('password', new FormControl('', Validators.required));
      this.updateForm.addControl('newPassword', new FormControl('',
        [Validators.required, Validators.minLength(6)]));
      this.updateForm.addControl('newPasswordConfirmation', new FormControl('',
        [Validators.required,  Validators.minLength(6)]));
    } else {
      this.updateForm.removeControl('password');
      this.updateForm.removeControl('newPassword');
      this.updateForm.removeControl('newPasswordConfirmation');
    }
  }

  async handleUpdate(): Promise<void> {
    this.submitted = true;
    if (this.updateForm.valid) {
        this.error = false;
        this.submitLoading = true;
        this.updateForm.disable();
        await this.authService.updateProfile({
          firstname: this.updateForm.controls.firstname.value,
          lastname: this.updateForm.controls.lastname.value,
          imageurl: this.avatar
        });
        if (this.editPassword) {
          try {
            this.error = false;
            await this.authService.changePassword(this.updateForm.value);
          } catch (e) {
            this.error = true;
            this.errorMsg = e.error.message;
            this.updateForm.controls.newPassword.setErrors({auth: e.error.message});
            this.submitLoading = false;
            this.updateForm.enable();
            return ;
          }
        }
        this.modal.hide();
        this.updateForm.enable();
        this.submitLoading = false;
    } else {
      if (this.updateForm.get('password')?.invalid) {
        this.errorMsg = 'Invalid Password';
      } else if (this.updateForm.get('newPassword')?.invalid) {
        this.errorMsg = 'Invalid New Password min 6 characters';
      } else if (this.updateForm.get('newPasswordConfirmation')?.invalid) {
        this.errorMsg = 'New Password Confirmation min 6 characters';
      } else {
        this.errorMsg = 'Invalid Input';
      }
      this.error = true;
    }
  }

  openAvatar(): void {
    this.showDropdown = false;
    this.uploadAvatarModel.open();
  }

  changeAvatar($event: any): void {
    this.avatar = $event;
  }

}
