<div class="container p-md-0 px-4" [style]="'--text-color: #000'">
  <h3 class="showing-matches-for">Showing matches for <span>"{{searchTerm}}"</span></h3>
  <div class="categories row mb-4">
    <div class="col">
      <div (click)="handleCategoryClick(item.id)" class="category-item mb-2" [ngClass]="{'active': selectedCategories.includes(item.id.toString())}" *ngFor="let item of categoryFilter">
        <p class="buttonsCTA-small">{{item.name}}</p>
      </div>
    </div>
  </div>
  <div class="_border-s"></div>
  <div *ngIf="contents.length" class="search-results-wrapper">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
      <div class="col item-elem" *ngFor="let item of contents; index as i; " >
        <a [routerLink]="'../' + item.url" >
          <div class="grid-img-wrapper" >
            <img class="grid-img"  *ngIf="item.type!.includes('page'); else showChannel"  [defaultImage]="item.coverThumbnailSmall ? item.coverThumbnailSmall : item.thumbnail"
                 [lazyLoad]="item.coverThumbnailLarge ? item.coverThumbnailLarge : item.mediumImg" [alt]="item.imageAlt" >
            <ng-template #showChannel >
              <img class="grid-img channel-image ii"  [defaultImage]="item.roundImage! ? item.roundImageSmall! : item.roundImage!"
                   [lazyLoad]="item.roundImage! ? item.roundImage!: item.roundImageLarge!" [alt]="item.imageAlt" >
            </ng-template>
          </div>
        </a>
        <div>
          <div  class="d-flex align-items-baseline justify-content-start">
            <h6 class="medium-p tag-item d-inline-block"> <span [ngClass]="item.tag.includes('Channel')?'channel-border':'' "  [innerHTML]="item.tag"> </span></h6>
            <div class="_border">
              <div class="border-line"></div>
            </div>
            <div class="share-wrapper d-inline-block">
              <app-share [white]="false" ></app-share>
            </div>
          </div>
          <a [routerLink]="'../' + item.url" >
            <h5>{{ item.title }}</h5>
          </a>
        </div>
      </div>
    </div>
    <div class="more" *ngIf="(paging.total && paging.total > 9) && (contents.length != paging.total)" >
      <div class="row">
        <div class="item-counter  col d-flex justify-content-center">
          <p>{{ contents.length }} of {{ paging.total  }}</p>
        </div>
      </div>
      <div class="row" >
        <div class="col d-flex justify-content-center">
          <app-rectangle-button dark (onClick)="handleShowMore()" [label]="'Show More'"></app-rectangle-button>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="loading">
    <div class="loading">
      <div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
