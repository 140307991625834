<ng-template #mobile>
    <div class="d-md-flex d-block col-12 col-lg-4 col-md-6 justify-content-between" *ngFor="let item of data.images | slice: start: end">
      <img class="img-item col-12 col-lg-9 col-md-10" src="{{ item.url }}" alt="{{ item.alt }}" loading="lazy" >
    </div>
  <div class="row"  *ngIf="end < total">
    <div class="item-counter  col d-flex justify-content-center">
      <p> {{ end }} of {{ total }}</p>
    </div>
  </div>
  <div class="row" *ngIf="end < total" >
    <div class="col d-flex justify-content-center">
      <app-rectangle-button (onClick)="handleShowMore()" [label]="'Show More'"></app-rectangle-button>
    </div>
  </div>
</ng-template>
<ng-template #desktop>
  <div class="d-md-flex d-block col col-lg-4 col-md-6 justify-content-between" *ngFor="let item of data.images">
    <img class="img-item col-lg-9 col-md-10" src="{{ item.url }}" alt="{{ item.alt }}" loading="lazy" >
  </div>
</ng-template>

<div class="grid-logo" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container">
    <div class="row p-0">
      <ng-container *ngTemplateOutlet="isMobileResolution? mobile : desktop"></ng-container>
    </div>
  </div>
</div>
