<div *ngIf="data$ | async as data">
  <footer class="">
    <!-- <div data-v-6da3ec0c="" class="custom-shape-divider" style="top: 0px;">
      <svg data-v-6da3ec0c="" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" class="" style="width: calc(119% + 1.3px); height: 89px;"><path data-v-6da3ec0c="" d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill" style="fill: rgb(255, 255, 255);"></path></svg>
    </div> -->
    <div class="d-flex justify-content-center h-100 w-100">
      <img class="d-none d-md-block position-absolute img1" src="/assets/img/draw-first.png" alt="img">
      <div class="d-flex flex-column align-items-center p-3">
        <h3 class="text-center">{{data.title}}</h3>
        <div class="d-flex align-items-center social flex-wrap">
          <a *ngFor="let item of data.socialMediaGroup.list" [href]="item.link" target="_blank">
            <img [src]="item.image.url" alt="img">
          </a>
          <!-- <a href="">
            <img src="/assets/svg/Share.svg" alt="">
          </a> -->
        </div>
        <p class="text-center" [innerHtml]="data.bottomText"></p>
      </div>
      <img class="d-none d-md-block position-absolute img2" src="/assets/img/draw-forth.png" alt="img">
    </div>
  </footer>
  <div class="info">
    <div class="a">
      <div>
        <!-- <span >{{item.link.title}}</span> -->
        <span><a href="/Imprint" target="_blank">Impressum</a></span>
        <span><a href="/Privacy" target="_blank">Datenschutz</a></span>
        <span (click)="onClick('#faq2')" [ngClass]="showLinks ? 'visible' : 'invisible'">FAQs</span>
      </div>
    </div>
    <a href="https://scoopandspoon.marketing/scoop-river-marketing-platform?lang=en" target="_blank" class="powered-by">
      <p>Powered by</p>
      <img src="../../../assets/svg/scoopriver.svg" alt="img">
    </a>
  </div>
</div>
