
<div class="header-wrap" *ngIf="$data | async as data">
  <div class="header d-flex justify-content-between" >
    <div class="d-flex justify-content-center align-items-center">
    <a href="./"><h4>augenarzt-weiz</h4></a>
    </div>
    <div class="d-none d-lg-flex" (change)="onChange($event)">
      <div class="d-flex align-items-center"> 
        <div *ngFor="let item of data.quickLinks">
          <h6 class="quick-link" [ngClass]="showLinks ? 'visible' : 'invisible'" (click)="onClick(item.link.url)">{{item.link.title}}</h6>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center">
        <!-- <div class="button"><span>Covid Info</span></div> -->
        <div class="button" [ngClass]="showLinks ? 'visible' : 'invisible'" (click)="onClick('#contact')"><span>Kontakt</span></div>
      </div>
    </div>
    <div class="hamburger-wrap d-flex d-lg-none align-items-center" (change)="onChange($event)" [ngClass]="showLinks ? 'visible' : 'invisible'">
      <svg (click)="handleMenu()" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="wlrvq498va" d="M0 0h24v24H0z"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="95jkjjtt9b" fill="#fff">
                <use xlink:href="#wlrvq498va"/>
            </mask>
            <path d="M21.985 16.5a1 1 0 0 1 .143 1.992l-.117.008-19.998.265a1 1 0 0 1-.143-1.992l.117-.008 19.998-.265zm.015-6a1 1 0 0 1 .117 1.993L22 12.5H2a1 1 0 0 1-.117-1.993L2 10.5h20zm0-6a1 1 0 0 1 .117 1.993L22 6.5H2a1 1 0 0 1-.117-1.993L2 4.5h20z" fill="#fff" fill-rule="nonzero" mask="url(#95jkjjtt9b)"/>
        </g>
    </svg>
    </div>
  </div>
  <div *ngIf="menu" class="menu d-flex flex-column justify-content-between position-fixed">
    <div class="d-flex flex-column">
      <div class="header d-flex justify-content-between">
        <div class="d-flex justify-content-center align-items-center">
        <h4>{{data.leftText}}</h4>
        </div>
        <div class="d-none d-lg-flex">
          <div class="d-flex align-items-center"> 
            <div *ngFor="let item of data.quickLinks">
              <span class="quick-link" (click)="onClick(item.link.url)">{{item.link.title}}</span>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="button"><span>{{data.buttonOne.title}}</span></div>
            <div class="button"><span>{{data.buttonTwo.title}}</span></div>
          </div>
        </div>
        <div class="hamburger-wrap d-flex d-lg-none align-items-center">
          <svg  (click)="handleMenu()"  width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <path id="n71ssrhg3a" d="M0 0h24v24H0z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <mask id="odjpgg966b" fill="#fff">
                    <use xlink:href="#n71ssrhg3a"/>
                </mask>
                <path d="M18.33 4.342a1 1 0 0 1 1.51 1.307l-.077.088L5.81 20.065a1 1 0 0 1-1.51-1.307l.077-.088L18.33 4.342zm1.52 14.093a1 1 0 0 1-1.328 1.492l-.087-.078L4.293 5.707A1 1 0 0 1 5.62 4.215l.087.078L19.85 18.435z" fill="#FFF" fill-rule="nonzero" mask="url(#odjpgg966b)"/>
            </g>
        </svg>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center text-center">
        <!-- <img src="/assets/img/face-2.png" alt=""> -->
        <div>
          <h2 class="mobile-links" *ngFor="let item of data.quickLinks" (click)="onClick(item.link.url)">{{item.link.title}}</h2>
        </div>
      </div>
    </div>
    <img src="../../../assets/img/draw-first.png" class="draw-first" alt="">
    <img src="../../../assets/img/draw-second.png" class="draw-second" alt="">
    <div class="d-flex flex-row justify-content-center align-items-center mobile-button-wrapper">
      <!-- <div class="button mobile"><span>{{data.buttonOne.title}}</span></div> -->
      <div class="button mobile" (click)="onClick('#contact')"><span>{{data.buttonTwo.title}}</span></div>
    </div>
  </div>
</div>