<div class="modal fade" #signupModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4>Registration</h4>
        <form [formGroup]="signupForm" (ngSubmit)="handleSignup()" autocomplete="off">
          <div class="form-item">
            <label for="firstName1">First name*</label>
            <input #firstName id="firstName1" placeholder="First name" type="text" formControlName="firstname">
            <div *ngIf="signupForm.controls.firstname.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.firstname.errors?.required">Please enter your First name.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="lastName1">Last name*</label>
            <input id="lastName1" placeholder="Last name" type="text" formControlName="lastname">
            <div *ngIf="signupForm.controls.lastname.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.lastname.errors?.required">Please enter your Last name.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="email1">Email address*</label>
            <input #email1 id="email1" placeholder="Email address" type="text" formControlName="email">
            <div *ngIf="signupForm.controls.email.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.email.errors?.email">Email address must be valid.</div>
              <div class="form-error" *ngIf="signupForm.controls.email.errors?.required">Please enter your Email address.</div>
            </div>
            <div class="form-error" *ngIf="signupForm.controls.email?.errors?.auth">{{signupForm.controls.email?.errors?.auth}}</div>
          </div>
          <div class="form-item">
            <label for="password1">Password*</label>
            <input #password1 id="password1" placeholder="Password"  type="password" formControlName="password">
            <div *ngIf="signupForm.controls.password.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.password?.errors?.required">Please enter your password.</div>
              <div class="form-error" *ngIf="signupForm.controls.password?.errors?.minlength">Your password must be at least 6 charters long.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="password2">Repeat Password*</label>
            <input id="password2" placeholder="Repeat password"  type="password" formControlName="password2">
            <div *ngIf="signupForm.controls.password2.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.password2?.errors?.required">Please enter your Repeat password.</div>
              <div class="form-error" *ngIf="signupForm.errors?.mismatch">Passwords doesn't match.</div>
            </div>
           </div>
          <div class="form-item">
            <div class="align-items-center">
              <input class="me-2" id="terms" type="checkbox" formControlName="agreed">
              <label class="my-0 paragraph small" for="terms">I hereby agree to the* </label><span>&nbsp;&nbsp;</span><a href="https://scoopandspoon.marketing/imprint" target="_self" class="paragraph small">Terms & Conditions</a>
            </div>
            <div *ngIf="signupForm.controls.agreed.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.agreed.errors?.required">Please read and accept terms and conditions.</div>
            </div>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button type="submit" class="w-100">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Register'"></app-rectangle-button>
            </button>
          </div>
          <div class="register-info">
            <p class="text-center">Already have an account? <a (click)="handleSwitch()">Sign in</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div #successModal class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content p-5 rounded-0">
      <h5>Please check your email inbox to confirm your account.</h5>
    </div>
  </div>
</div>
