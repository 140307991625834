<div id={{data.id}} *ngIf="data.type == 'large'">
    <h2>Galerie</h2>
    <div class="large-wrapper">
        <div class="col-12 carousel-wrapper">
          <app-carousel (afterChange)="handleChange($event)" #slider [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of data.pictures; let i = index">
              <div class="slide-item">
                <!-- <img [defaultImage]="item.image.sizes.thumbnail" [lazyLoad]="item.image.url" alt="img" > -->
                <div class="url-image" [ngStyle]="{'background-image':' url(' + item.image.url + ')'}" ></div>
              </div>
            </ng-template>
          </app-carousel>
          <div class="container button-container1">
            <div class="arrow-wrap" [ngClass]="prevArrowDisable?'arrowVisible': 'arrowDisable'"  (click)="handleNavigate('PREV')">
              <app-arrow-previous></app-arrow-previous>
            </div>
            <div class="arrow-wrap" [ngClass]="nextArrowDisable?'arrowVisible': 'arrowDisable'"  (click)="handleNavigate('NEXT')" >
              <app-arrow-next></app-arrow-next>
            </div>
          </div>
        </div>
        <div class="circle-wrapper">
          <div
            class="circle"
            [ngClass]="{'active': slider.currentPositionSlide - 1 == i}"
            *ngFor="let item of data.pictures; let i = index">

          </div>
        </div>
      </div>
</div>
<div id={{data.id}}  class="small-wrapper">
  <div *ngIf="data.type == 'small'" class="small position-relative">
    <div class="container-fluid p-md-0 pe-0">
      <div class="container">
        <h2>{{data.title}}</h2>
        <div class="p" [innerHTML]="transformYourHtml(data.description)"></div>
      </div>
      <div class="container-fluid p-lg-0 pe-0 ps-2">
        <div class="d-flex justify-content-end">
          <div class="col-12 f-content">
            <div class="container button-container1 small-btn-ctnr">
              <div class="arrow-wrap" [ngClass]="prevArrowDisable?'arrowVisible': 'arrowDisable'" (click)="handleNavigate('PREV')">
                <app-arrow-previous></app-arrow-previous>
              </div>
              <div class="arrow-wrap" [ngClass]="nextArrowDisable?'arrowVisible': 'arrowDisable'" (click)="handleNavigate('NEXT')" >
                <app-arrow-next></app-arrow-next>
              </div>
            </div>
            <app-carousel (afterChange)="handleChange2($event)"  #slider dark [slideConfig]="_slideConfigSmall">
              <ng-template misCarouselItem *ngFor="let item of data.pictures; let i = index">
                <a  class="position-relative">
                  <div class="slide recommender-slider"
                       [defaultImage]="item.image.sizes.large"
                       [lazyLoad]="item.image.sizes.large" >
                  </div>
                </a>
                <div class="under-container h-100">
                  <div class="d-flex">
                    <h6 class="medium-p tag-item d-inline-block social-title"[innerHTML]="item.tag"></h6>
                  </div>
                  <div class="d-flex align-items-end h-100">
                    <div>
                      <a>
                        <h5 class="item-title mobile2-leed">{{ item.title}}</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </app-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
