import { AfterViewInit, Component, OnInit } from '@angular/core';
import Player from '@vimeo/player';
import { VIMEO } from '../../models/mocks/vimeo.mock';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Component({
  selector: 'app-vimeo-vide',
  templateUrl: './vimeo-vide.component.html',
  styleUrls: ['./vimeo-vide.component.scss']
})
export class VimeoVideComponent implements OnInit, AfterViewInit {
  private player: any;
  private themeColor: string;
  data = VIMEO;

  constructor(
    private generalSettingsService: GeneralSettingsService
  ) { }

  /*@HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    this.pipScroll();
  }*/

  ngOnInit(): void {
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.themeColor = theme.color;
    });
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  private initPlayer(): any {
    this.player = new Player('video-iframe');
    this.player.setColor(this.themeColor).then((color: string) => {}).catch((error: any) => {
    });
  }

  getVimeoVideo(id: any): string {
    if ( id ) {
      return id + '?controls=1&loop=0&muted=0';
    }
    return '';
  }

  /*
  example for pip
  pipScroll(): void {
    this.player.requestPictureInPicture().then((pip: any) => {
      // the player entered picture-in-picture
    }).catch((error: any) => {
      console.log('can not put in pip', error);
    });
  }*/
}
