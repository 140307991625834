<div class="modal fade" #loginModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4>Sign in</h4>
        <form [formGroup]="loginForm" (ngSubmit)="handleLogin()">
          <div class="form-item">
            <label for="email">Email address</label>
            <input #emailAddress id="email" placeholder="Email address" type="text" formControlName="email">
            <div *ngIf="loginForm.controls.email.touched && submitted">
              <div class="form-error" *ngIf="loginForm.controls.email.errors?.email">Email address must be valid.</div>
              <div class="form-error" *ngIf="loginForm.controls.email.errors?.required">Please enter your email address.</div>
            </div>
          </div>
          <div class="form-item mb-2">
            <label for="password">Password</label>
            <input type="password" id="password" placeholder="Password"  formControlName="password">
            <div *ngIf="loginForm.controls.password.touched && submitted">
              <div class="form-error" *ngIf="loginForm.controls.password?.errors?.required">Please enter your password.</div>
            </div>
            <div class="form-error" *ngIf="loginForm.controls.password?.errors?.auth">{{loginForm.controls.password?.errors?.auth}}</div>
          </div>
          <a (click)="handleSwitchToForgot()">Forgot Password?</a>
          <p *ngIf="isActivated" class="alert alert-success border-0 mt-3">Account successfully activated, now you can sign in!</p>
          <div class="button-wrapper d-flex justify-content-center">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Sign In'"></app-rectangle-button>
            </button>
          </div>
          <div class="register-info">
            <p class="text-center">New to SCOOP & SPOON? <a (click)="handleSwitch()">Register</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
