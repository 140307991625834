import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { GeneralSettingsService } from './services/general-settings.service';
import { CookieService } from 'ngx-cookie';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { TrackingData } from './models/general-settings.model';


declare var Cookiebot: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  sessionColor: string;
  constructor(private generalSettings: GeneralSettingsService,
              private cookieService: CookieService,
              private renderer2: Renderer2,
              @Inject(PLATFORM_ID) private platformId: object,
              @Inject(DOCUMENT) private document: Document,
              ) {
  }
  ngOnInit(): void {
    this.generalSettings.$data.subscribe(({data: {trackingData}}) => {
      if (!trackingData) {return; }
      this.loadGoogleTracking(trackingData);
    });
  }
  loadGoogleTracking(trackingData: TrackingData): void {
    const script = this.renderer2.createElement('script');
    script.type = `text/plain`;
    script.dataset.cookieconsent = 'statistics';
    script.text = trackingData.headScript;

    this.renderer2.appendChild(this.document.head, script);
  }
}
