<!--<div class="modal fade" #myDataModel id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<div class="modal fade" #myDataModel id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4>My Data</h4>
        <div class="d-flex justify-content-center">
          <div class="profile-picture position-relative">
            <img src="{{ avatar }}" alt="avatar">
            <div class="add-avatar position-absolute" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal" >
              <img src="assets/svg/avatar.svg" alt="add avatar" width="24" height="24">
            </div>
          </div>
        </div>
        <form [formGroup]="updateForm" (ngSubmit)="handleUpdate()">
          <div class="form-item">
            <label for="email">First name</label>
            <input #firstName id="firstname" placeholder="First name" type="text" formControlName="firstname">
            <div *ngIf="updateForm.controls.firstname.touched || submitted">
              <div class="form-error" *ngIf="updateForm.controls.firstname.errors?.required">You cannot leve this empty.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="email">Last name</label>
            <input id="lastname" placeholder="Last name" type="text" formControlName="lastname">
            <div *ngIf="updateForm.controls.lastname.touched || submitted">
              <div class="form-error" *ngIf="updateForm.controls.lastname.errors?.required">You cannot leve this empty.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="email">Email Address</label>
            <input #emailAddress id="email" placeholder="Email Address" type="text" formControlName="email">
          </div>
          <div class="form-item" *ngIf="!editPassword">
            <label for="empty-password">Password</label>
            <div class="d-flex">
              <input class="w-100" disabled type="password" id="empty-password" placeholder="Password">
              <p (click)="handleSwitch()" class="edit-password">Edit</p>
            </div>
          </div>
          <div *ngIf="editPassword">
            <div class="form-item">
              <label for="password">Password</label>
              <div class="d-flex">
                <input class="w-100" type="password" id="password" placeholder="Password"  formControlName="password">
                <p (click)="handleSwitch()" class="edit-password">cancel</p>
              </div>
              <div *ngIf="updateForm.controls.password.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.password?.errors?.required">Please enter your password.</div>
              </div>
            </div>
            <div class="form-item">
              <label for="new-password">New Password</label>
              <input id="new-password" placeholder="New Password" type="password" formControlName="newPassword">
              <div *ngIf="updateForm.controls.newPassword.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.newPassword?.errors?.required">Please enter your new password.</div>
              </div>
              <div class="form-error" *ngIf="updateForm.controls.newPassword?.errors?.auth">{{updateForm.controls.newPassword?.errors?.auth}}</div>
            </div>
            <div class="form-item">
              <label for="new-password-confirmation">New Password Confirmation</label>
              <input id="new-password-confirmation" placeholder="New Password Confirmation" type="password" formControlName="newPasswordConfirmation">
              <div *ngIf="updateForm.controls.newPasswordConfirmation.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.newPasswordConfirmation?.errors?.required">Please enter your new password again.</div>
                <div class="form-error" *ngIf="!updateForm.controls.newPasswordConfirmation?.errors?.required && updateForm.errors?.mismatch">Passwords doesn't match.</div>
              </div>
            </div>
          </div>
          <p *ngIf="error" class="alert alert-danger border-0 mt-3">{{ errorMsg }}</p>
          <div class="button-wrapper d-flex justify-content-center flex-column">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Save'"></app-rectangle-button>
            </button>
            <button (click)="modal.hide()" class="w-100" >
              <app-rectangle-button  [label]="'Cancel'"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-upload-image-popup #uploadAvatar (avatar)="changeAvatar($event)"></app-upload-image-popup>
