import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import {
  algorithmType,
  Content,
  Paging,
  SearchOptions,
  SearchResponse
} from '../../models/limitless-interaction.model';
import { isPlatformServer, Location } from '@angular/common';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  searchTerm = '';
  contents: Content[] = [];
  paging: Paging = {currentPage: 0};
  algorithmType: algorithmType;
  categoryFilter: any[];
  loading = true;
  loads = 0;
  page: number;
  data: any;
  selectedCategories: string[] = [];
  PER_PAGE = 9;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private limitlessInteractionService: LimitlessInteractionService,
    private generalSettings: GeneralSettingsService,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    const { term, categories, page } = this.route.snapshot.queryParams;
    this.searchTerm = term;
    this.selectedCategories = categories ? categories.split(',') : [];
    this.page = page ? +page : 1;
  }

  async ngOnInit(): Promise<Promise<any> | void> {
    if (!this.searchTerm) {
      return await this.router.navigate(['home'], {replaceUrl: true});
    }

    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.loadFilterCategories();
    this.loadSearchResults({initial: true});
  }

  loadFilterCategories(): void {
    this.generalSettings.$data.pipe(map(({data}) => data.search)).subscribe(searchConfig => {
      this.algorithmType = searchConfig.searchAlgorithm;
      this.categoryFilter = searchConfig.categories;
    });
  }

  loadSearchResults(opt?: {initial: boolean}): void {
    let perPage = this.PER_PAGE;
    if (opt?.initial) {
      perPage = this.page * this.PER_PAGE;
    }
    const options: SearchOptions = {
      keyword: this.searchTerm,
      algorithmType: this.algorithmType,
      paging: { perPage , currentPage: opt?.initial ? 1 : this.page},
      categoryIds: this.selectedCategories,
    };
    this.limitlessInteractionService.getSearch(options).subscribe((data: SearchResponse) => {
      this.loading = false;
      this.paging = data.paging;
      // @ts-ignore
      this.contents = this.contents.concat(data.contents);
      this.addUrlState();
    });
  }

  handleCategoryClick(id: string): void {
    const idIndex = this.selectedCategories.indexOf(id.toString());
    if (idIndex >= 0) {
      this.selectedCategories.splice(idIndex, 1);
    } else {
      this.selectedCategories.push(id.toString());
    }
    this.loading = true;
    this.page = 1;
    this.contents = [];
    this.loadSearchResults();
  }

  handleShowMore(): void {
    this.page = this.page + 1;
    this.loadSearchResults();
  }

  addUrlState(): void {
    const url = this.router.createUrlTree(['search'], {queryParams: {
        term: this.searchTerm,
        page: this.page,
        categories: this.selectedCategories.join(',')
      }}).toString();
    this.location.go(url);
  }
}
