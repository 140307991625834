import { Component, Input, OnInit } from '@angular/core';
import { ContactInfo } from '../../models/contact-info.model';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  @Input() data: ContactInfo;
  constructor() { }

  ngOnInit(): void {
    console.log('data', this.data);
  }

}
