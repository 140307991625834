
<div class="video-component container px-md-0" >
  <div>
    <div class="row" *ngIf="data.vimeoVideoTitle">
      <h2>{{ data.vimeoVideoTitle }}</h2>
    </div>
    <div class="row">
      <div class="col-12  video-iframe-content" [ngClass]="data.fullSizeVimeoVideo? 'col-12 full-size-video' : 'col-md-7'">
        <div class="px-2 py-0 p-md-0">
          <iframe id="video-iframe"
                  [src]="getVimeoVideo(data.vimeoVideoVimeoId) | vimeoUrl"
                  data-cookieconsent="marketing"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="data.vimeoVideoDescription">
      <div>
        <div class="col-12 col-md-7 px-2 p-md-0">
          <p>{{data.vimeoVideoDescription}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

