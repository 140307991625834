import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook-icon',
  templateUrl: './facebook-icon.component.html',
  styleUrls: ['./facebook-icon.component.scss']
})
export class FacebookIconComponent implements OnInit {
  @Input() title: string;
  @Input() linkToPage: string;

  constructor() { }

  ngOnInit(): void { }

  getLink(): string {
    if (this.linkToPage) {
      let url = window.location.protocol + '//' + window.location.host + '/' + this.linkToPage;
      if (this.title.length > 0) {
        url = url + '&text=' + encodeURIComponent(this.title);
      }
      return 'https://www.facebook.com/sharer/sharer.php?u=' + (encodeURIComponent(url));
    }
    return '';
  }
}
