<div class="modal fade" #changePassword id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4>Forgot Password</h4>
        <form [formGroup]="changePasswordForm" (ngSubmit)="handleChange()">
          <div class="form-item">
            <label for="newPassword">New Password</label>
            <input id="newPassword" placeholder="New Password" type="password" formControlName="newPassword" autocomplete="off">
            <div *ngIf="changePasswordForm.controls.newPassword.touched || submitted">
              <div class="form-error" *ngIf="changePasswordForm.controls.newPassword.errors?.required">Please enter your new password.</div>
            </div>
          </div>
          <div class="form-item">
            <label for="newPasswordConfirm">Confirm New Password</label>
            <input id="newPasswordConfirm" placeholder="Confirm New Password" type="password" formControlName="newPasswordConfirm" autocomplete="off">
            <div *ngIf="changePasswordForm.controls.newPassword.touched || submitted">
              <div class="form-error" *ngIf="changePasswordForm.controls.newPasswordConfirm.errors?.required">Please enter your new confirm password.</div>
              <div class="form-error" *ngIf="changePasswordForm.errors?.mismatch">Passwords doesn't match.</div>
            </div>
            <div class="form-error" *ngIf="changePasswordForm.controls.password?.errors?.auth">{{changePasswordForm.controls.password?.errors?.auth}}</div>
            <p *ngIf="success" class="alert alert-success border-0 mt-3">New password successfully set!</p>
            <p *ngIf="error" class="alert alert-danger border-0 mt-3">{{ errorMsg }}</p>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Submit'"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
