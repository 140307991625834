export const environment = {
  production: true,
  pageUrl: 'https://dev:scoop@augenarzt-weiz.at/wordpress/wp-json/scoop/v1/page/',
  angularUrl: 'https://augenarzt-weiz.at/',
  baseUrl: 'https://augenarzt-weiz.at',
  wpJson: 'https://augenarzt-weiz.at/wordpress/wp-json/scoop/v1/',
  hj: '2480302',
  recaptcha: '6Le2csoiAAAAAI8IjWVkBvyh8-dI1xQCu03qrZsj',

};
