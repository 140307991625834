import {Injectable, Injector} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(
    private http: HttpClient,
    private injector: Injector
  ) {}

  public endpoint(endpoint: string): string {
    return `${environment.pageUrl}${endpoint}`;
  }

  public post<T>(endpoint: string, data: any, headers?: HttpHeaders, silent: boolean = false): Observable<T> {
    let reqHeaders = this.getHeaders();
    if (headers) {
      reqHeaders = headers;
    }
    endpoint = this.endpoint(endpoint);
    return this.http
      .post<T>(endpoint, data, { headers: reqHeaders, observe: 'response', withCredentials: true })
      .pipe(
        map((res: any) => {
          // console.log(`%cRES POST ${endpoint}`, 'color: green;', res);
          return res.body;
        }),
        catchError(this.handleError.bind(this, silent)),
        finalize(() => {
          if (!silent) {
            // this.spinnerService.hide();
          }
        }),
      );
  }

  public get<T>(endpoint: string, queryParams?: { [key: string]: string }, headers?: HttpHeaders, silent: boolean = false): Observable<T> {
    let reqHeaders = this.getHeaders();
    if (headers) {
      reqHeaders = headers;
    }
    endpoint = this.endpoint(endpoint);
    endpoint = this.appendQueryParams(endpoint, queryParams);
    return this.http
      .get<T>(endpoint, { headers, observe: 'response', withCredentials: true })
      .pipe(
        map((res: any) => {
          // console.log(`%cRES GET ${endpoint}`, 'color: green;', res);
          return res.body;
        }),
        catchError(this.handleError.bind(this, silent)),
        finalize(() => {
          if (!silent) {
            // this.spinnerService.hide();
          }
        }),
      );
  }

  public put<T>(endpoint: string, data: any, silent: boolean = false): Observable<T> {
    const headers = this.getHeaders();
    endpoint = this.endpoint(endpoint);
    return this.http
      .put<T>(endpoint, data, { headers, observe: 'response', withCredentials: true })
      .pipe(
        map((res: any) => {
          // console.log(`%cRES PUT ${endpoint}`, 'color: green;', res);
          return res.body;
        }),
        catchError(this.handleError.bind(this, silent)),
        finalize(() => {
          if (!silent) {
            // this.spinnerService.hide();
          }
        }),
      );
  }

  public delete<T>(endpoint: string, silent: boolean = false): Observable<T> {
    const headers = this.getHeaders();
    endpoint = this.endpoint(endpoint);
    return this.http
      .delete<T>(endpoint, { headers, observe: 'response', withCredentials: true })
      .pipe(
        map((res: any) => {
          return res.body;
        }),
        catchError(this.handleError.bind(this, silent)),
        finalize(() => {
          if (!silent) {
            // this.spinnerService.hide();
          }
        }),
      );
  }

  private handleError(silent: boolean, errorResponse: HttpErrorResponse): Observable<never> {
    if (errorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', errorResponse.error.message);
    } else {
      switch (errorResponse.status) {
        case 404: {
          return this.injector.get(Router).navigate(['/not-found']) as any;
        }
        case 403: {
          // this.router.navigate(['/login']);
          break;
        }
        case 401: {
          // this.router.navigate(['/login']);
          break;
        }
      }
    }
    return throwError(errorResponse.error);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    // headers = headers.append('Authorization', `Basic ${window.btoa('dev:scoop')}`);
    return headers;
  }

  private appendQueryParams(endpoint: string, queryParams?: { [key: string]: string }) {
    const urlSearchParams = new URLSearchParams(endpoint);
    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams[key]) {
          urlSearchParams.append(key, queryParams[key]);
        }
      }
      endpoint = decodeURIComponent(urlSearchParams.toString());
    }
    return endpoint;
  }
}
