<div id={{data.id}} class="accordion-wrapper">
  <div class="container _container">
    <h2 class="title">{{data.title}}</h2>
    <p class="text-center description">{{data.description}}</p>
    <div class="accordion" id="accordionExample + data.id">
      <div class="accordion-item" *ngFor="let item of data.accordions;let i = index">
        <h2 class="accordion-header" id="{{'headingOne' + item.id}}">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="{{'#collapseOne' + item.id}}" aria-expanded="false" attr.aria-controls="{{'collapseOne' + item.id}}">
            {{item.title}}
          </button>
        </h2>
        <div id="{{'collapseOne' + item.id}}" class="accordion-collapse collapse " attr.aria-labelledby="{{'headingOne' + item.id}}" data-bs-parent="#accordionExample + item.id">
          <div class="accordion-body">
            <p  [innerHTML]="item.content"></p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- <div class="button-container">
        <div class="button">
          <span>{{data.buttonText}}</span>
        </div>
      </div> -->
    </div>
  </div>
</div>
