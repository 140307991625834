<div id="preview" class="preview-recommender-wrapper position-relative" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container">
    <h2 class="text-center prev-title">{{data.title}}</h2>
    <!-- <p>Gerne bemüht sich mein engagiertes und hochqualifiziertes Team um Ihre Anliegen.</p> -->
  </div>
  <div class="container-md px-6 px-md-0 d-flex mb-4  justify-content-md-end">
    <app-link-to *ngIf="data.link?.url" [link]="data.link?.url"  [title]="data.link?.title"></app-link-to>
  </div>
  <div class="container-fluid p-0" *ngIf="recData.length">
    <div class="_custom-wrapper">
      <div class="col-12  f-content position-relative">
        <div class="col-12 col-md-4 text-column slider-text">
            <h3 class="mobile3">{{recData[currentSlide].title}}</h3>
            <p class="description" [ngClass]="isForMobileVisible?'mobile-visible': 'mobile-disabled'" [innerHTML]="recData[currentSlide].tag"></p>
         <!-- <div class="link-button">
            <app-underline-button
              [color]="data.textColor"
              [link]="recData[currentSlide].url"
              [text]="'Learn More'"
            >
            </app-underline-button>
          </div>-->
        </div>
        <div class="col-12 col-md-8 carousel">
          <app-carousel (afterChange)="handleChange($event)" #slider  [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData; let i = index">
                <div class="slide-item-wrapper-wrapper">
                  <div class="slide-item-wrapper">
                    <!-- <div class="slide-item"   [defaultImage]="item.coverThumbnailSmall ? item.coverThumbnailSmall : item.thumbnail"
                         [lazyLoad]="item.coverThumbnailLarge ? item.coverThumbnailLarge : item.mediumImg" >
                      <app-video-play [isVideo]="item.hasVideo"></app-video-play>
                    </div> -->
                    <div class="slide-item" [ngStyle]="{'background-image':' url(' + item.mediumImg + ')'}" ></div>
                  </div>
                </div>
            </ng-template>
          </app-carousel>
        </div>
        <div class="container button-container2">
          <div class="arrow-wrap" [ngClass]="prevArrowDisable?'arrowVisible': 'arrowDisable'" (click)="handleNavigate('PREV')">
            <app-arrow-previous></app-arrow-previous>
          </div>
          <div class="arrow-wrap" [ngClass]="nextArrowDisable?'arrowVisible': 'arrowDisable'" (click)="handleNavigate('NEXT')" >
            <app-arrow-next></app-arrow-next>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
