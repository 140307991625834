<div id="element5">
  <div class="container px-0">
    <h2 class="text-center">{{data.title}}</h2>
    <p class="description text-center">{{data.description}}</p>
    <div class="row d-md-flex cards">
      <div class="col-6 col-xl-4 icon-cards" *ngFor="let item of data.pictures;let i = index">
        <div class="d-flex align-items-center justify-content-center _tile" [attr.id]="'triggerModalbutton-' + i" data-bs-toggle="modal" [attr.data-bs-target]="'#exampleModal-' + i">
          <img  [src]="item.image.sizes.large" alt="img">
          <h4>{{item.title}}</h4>
          <div class="modal fade"  [attr.id]="'exampleModal-' + i" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{item.title}}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <img class="modal-img" [src]="item.image.sizes.large" alt="img">
                <div class="modal-body" [innerHTML]="transformYourHtml(item.description)"></div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
  <!-- <app-carousel class="d-md-none test" #slider [slideConfig]="_slideConfig">
    <ng-template misCarouselItem *ngFor="let item of data.pictures; let i = index">
      <div class="d-flex align-items-center justify-content-center _tile"  [attr.id]="'triggerModalbutton-' + i" data-bs-toggle="modal" [attr.data-bs-target]="'#exampleModal1-' + i">
        <img  [src]="item.image.sizes.large" alt="">
        <h4>{{item.title}}</h4>
        <div class="modal fade" [attr.id]="'exampleModal1-' + i" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{item.title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <img class="modal-img" [src]="item.image.sizes.large" alt="">
              <div class="modal-body" [innerHTML]="transformYourHtml(item.description)"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-carousel> -->
  <!-- <div class="container button-container d-md-none">
    <div class="arrow-wrap" (click)="handleNavigate('PREV')">
      <app-arrow-previous></app-arrow-previous>
    </div>
    <div class="arrow-wrap" (click)="handleNavigate('NEXT')" >
      <app-arrow-next></app-arrow-next>
    </div>
  </div> -->
</div>