import { Component, OnInit } from '@angular/core';
import { Channel } from '../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss']
})
export class ChannelListComponent implements OnInit {
  data: any;
  preferences: any;
  isDataAvailable = false;
  contentsAll: any = [];
  contentsToShow: any = [];
  contentsPerPage = 9;

  constructor(
    private subscribeService: LimitlessInteractionService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.contentsAll = [];
    this.contentsToShow = [];
    const options: Channel = {
      channels: this.activatedRoute.snapshot.data.preferences.data.id + ''
    };
    this.subscribeService.getContentChannels(options).subscribe(data => {
      this.data = data.contents[0];
      this.contentsAll =  data.contents[0].channelContents;
      this.contentsToShow = this.contentsAll.slice(0, +this.contentsPerPage);
      this.isDataAvailable = true;
    });
  }

  showMore(event: any): any {
    this.contentsToShow = this.contentsToShow.concat(
      this.contentsAll.slice(this.contentsToShow.length,  this.contentsToShow.length + +this.contentsPerPage));
  }

  checkIsVideo(videoId: any): boolean {
    return videoId.length > 0;
  }

}
