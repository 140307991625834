import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter, HostListener, Inject,
  Input,
  OnInit,
  Output, PLATFORM_ID,
  QueryList,
  ViewChild
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CarouselItemDirective } from '../../directives/carousel-item.directive';
import { SliderPositionArrows } from '../carousel/carousel.component';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent implements OnInit, AfterViewInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ContentChildren(CarouselItemDirective) itemTemplates: QueryList<CarouselItemDirective>;
  @Input() slidesToShow: number;
  @Input() dark: string;
  @Input() isArrowContainer: boolean;
  @Input() maxItems: number;
  @Input() position: SliderPositionArrows;
  @Output() breakpoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() beforeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() init: EventEmitter<any> = new EventEmitter<any>();
  public instance: any;
  public currentSlide: any;
  sliderPositionArrows = SliderPositionArrows;
  first = false;
  last = false;

  // tslint:disable-next-line
  private _slideConfig: any = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };

  @Input() set slideConfig(val: any) {
    if (val) {
      this._slideConfig = val;
    }
  }

  get slideConfig(): any {
    return this._slideConfig;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): any {
    if (typeof this.slidesToShow !== 'undefined') {
      this.slideConfig.slidesToShow = this.slidesToShow;
      this.slideConfig.slidesToScroll = this.slidesToShow;
    }
  }

  ngAfterViewInit(): void {
    this.getScreenSize();
    this.cdr.markForCheck();
    this.slickModal.breakpoint.subscribe((b) => {
      const point = b.breakpoint;
      const responsive = this.slideConfig.responsive.find((r: any) => r.breakpoint === point);
      if (responsive) {
        this.slidesToShow = responsive.settings.slidesToShow;
      }
    });
  }

  onAfterChange(e: { event: any; slick: any; currentSlide: number; first: boolean; last: boolean }): void {
    this.first = e.first;
    this.last = e.last;

    const { slick, currentSlide } = e;
    const slidesToShow = slick.options.slidesToShow;
    if (slidesToShow + currentSlide >= slick.slideCount) {
      this.last = true;
    }
    this.afterChange.emit(e);
  }

  slickInit(event: any): void {
    this.instance = event.slick;
    this.instance.setDimensions();
    this.cdr.markForCheck();
    this.init.emit(event);
    this.instance.slickGoTo(0);
    setTimeout(() => {
      this.instance.setDimensions();
    }, 250);
  }

  public setDimensions(): void {
    this.instance.setDimensions();
  }

  public get templates(): CarouselItemDirective[] {
    return this.itemTemplates.toArray();
  }

  onBreakpoint(event: any): void {
    event.slick.setDimensions();
    this.instance.setDimensions();
    this.breakpoint.emit(event);
  }

  prev(): void {
    this.slickModal.slickPrev();
  }

  next(): void {
    this.slickModal.slickNext();
    this.cdr.markForCheck();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any): void {
    if (this.slickModal !== undefined && isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        if (!this.slickModal.initialized) {
          this.slickModal.initSlick();
        }
      } else {
        if (this.slickModal.initialized) {
          this.slickModal.unslick();
        }
      }
    }
  }
}
