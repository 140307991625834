<div class="get-in-touch" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">{{data.title}}</h2>
      </div>
      <div class="col-12">
        <p class="description">{{data.description}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0">
        <div class="contact-info h-100">
          <div class="d-flex">
            <!-- <h3 class="contact-title">Kontakt</h3> -->
          </div>
          <div class="" *ngFor="let item of data.contactItems">
            <h5 [innerHtml]="item.title"></h5>
            <div class="d-flex contact-item">
              <img [src]="item.icon.sizes.large" alt="img">
              <p [innerHtml]="item.text"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)" class="h-100">
          <h3 class="form-title">Kontaktformular</h3>
          <img class="note" src="../../../assets/svg/mask.svg" alt="img">
          <input [disabled]="loading" type="hidden" id="referrer" name="referrer" value="{{ previewPageUrl }}"  #referrer="ngModel" [(ngModel)]="previewPageUrl">
          <div class="form-group">
            <input [disabled]="loading" required
                   ngModel name="first_name" id="first_name" type="text" class="form-control" #first_name="ngModel" [placeholder]="contactForm.submitted && !first_name.valid ? 'Bitte geben Sie Ihren Vornamen ein' : 'Vorname *'"  (focus)="setFocus('first_name')"
                   (focusout)="setFocus('')"
                   [ngClass]="{alert: contactForm.submitted && !first_name.valid}"
            >
          </div>
          <div class="form-group">
            <input [disabled]="loading" required
                   ngModel name="last_name" id="last_name" type="text" class="form-control" #last_name="ngModel" [placeholder]="contactForm.submitted && !first_name.valid ? 'Bitte geben Sie Ihren Nachnamen ein' : 'Nachname *'"  (focus)="setFocus('last_name')"
                   (focusout)="setFocus('')"
                   [ngClass]="{alert: contactForm.submitted && !last_name.valid}"
            >
          </div>
          <div class="form-group">
            <input required
                   email
                   [disabled]="loading"
                   ngModel name="email"
                   id="email"
                   type="text"
                   class="form-control"
                   #email="ngModel"
                   [email]="validateEmail(email.value)"
                   [ngClass]="{alert: ( contactForm.submitted && !isValideMail) || (!isValideMail && email.touched), selected: focusElem == 'email' }"
                   [placeholder]="contactForm.submitted && !isValideMail ? 'Bitte geben Sie Ihre E-Mail Adresse ein' : 'E-Mail-Adresse *'"
                   (focus)="setFocus('email')"
                   (focusout)="setFocus('')"
            >
            <!-- <div class="alert" *ngIf="!isValideMail && email.touched"> Email not valid</div> -->
          </div>
          <div class="form-group">
            <div class="form-group">
              <input [disabled]="loading" required
                     ngModel name="phone" id="phone" type="text" class="form-control" #phone="ngModel" [placeholder]="contactForm.submitted && !phone.valid ? 'Bitte geben Sie Ihre Telefonnummer ein' : 'Telefonnummer *'"  (focus)="setFocus('phone')"
                     (focusout)="setFocus('')"
                     [ngClass]="{alert: contactForm.submitted && !phone.valid}"
              >
            </div>
          </div>
          <div class="form-group">

            <textarea
                [ngClass]="{alert: contactForm.submitted && !message.valid}"
                [disabled]="loading"
                required ngModel
                name="message"
                id="message"
                rows="4"
                cols="30"
                #message="ngModel"
                [placeholder]="contactForm.submitted && !message.valid ? 'Bitte geben Sie eine Nachricht ein' : 'Nachricht *' "
                (focus)="setFocus('message')"
                (focusout)="setFocus('')" >
            </textarea>
          </div>
          <div class="form-check d-flex ">
            <input ngModel class="form-check-input" type="checkbox" value="" id="recall" name="recall"  #recall="ngModel" >
            <label class="form-check-label text-white" for="recall"
            >
            Um Rückruf wird gebeten
            </label>
          </div>
          <div class="form-check d-flex ">
            <input ngModel required class="form-check-input" type="checkbox" value="" id="pp" name="pp"  #pp="ngModel" >
            <label class="form-check-label text-white" for="pp"
              [ngClass]="{alert: contactForm.submitted && pp.invalid, selected: focusElem == 'pp' }"
            >
            <a class="privacy pp paragraph" href="./Privacy" target="_blank">Ich stimme den <span>Datenschutzbestimmungen</span> zu *</a>
            </label>
          </div>
          <!-- <a class="pp paragraph text-white" href="./Privacy" target="_blank">Ich stimme den Datenschutzbestimmungen zu *</a> -->
          <div class="form-group d-flex">
            <button [disabled]="loading" class="form-button-submit" type="submit">
              <div *ngIf="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span class="" *ngIf="!loading">
               Nachricht senden
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-toast #toast></app-toast>
