import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { MyHistory } from '../../models/my-history';

@Component({
  selector: 'app-my-history',
  templateUrl: './my-history.component.html',
  styleUrls: ['./my-history.component.scss']
})
export class MyHistoryComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  data: MyHistory;
  isOpen = false;
  isShow = false;
  isHistoryTrack = true;
  constructor( private historyService: LimitlessInteractionService ) { }

  ngOnInit(): void {
    this.historyService.getHistoryContents().subscribe(data => {
      if (data?.status === 200) {
          this.data = data;
          this.isShow = true;
          this.checkIsHistoryTrack();
      } else if (data?.status === undefined) {
        this.isHistoryTrack = false;
      } else {
        this.isShow = true;
        this.isHistoryTrack = false;
        console.log('Error');
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: MouseEvent): void {
    if (this.toggleButton !== undefined) {
      if (!this.toggleButton.nativeElement.contains(event.target)){
        this.isOpen = false;
      }
    }
  }

  private checkIsHistoryTrack(): void {
    this.historyService.getHistoryIsTrack().subscribe(data => {
      if (data?.status === 200) {
        this.isHistoryTrack = data.contentHistoryPreference === '1';
      } else if (data?.status === undefined) {
        console.log('No data');
        this.isHistoryTrack = false;
      } else {
        this.isShow = true;
        this.isHistoryTrack = false;
        console.log('Error');
      }
    });
  }

  getDate(): number {
    return Date.now();
  }

  public onSelect(value: string): void {
    if ( value === '0') {
      this.historyService.getPauseHistory().subscribe(data => {
        if (data?.status === 200) {
          this.isHistoryTrack = data.contentHistoryPreference === '1';
        } else if (data?.status === undefined) {
          console.log('No data');
        } else {
          console.log('Error');
        }
      });
    } else if ( value === '1' ) {
      this.historyService.getPlayHistory().subscribe(data => {
        if (data?.status === 200) {
          this.isHistoryTrack = true;
        } else if (data?.status === undefined) {
          console.log('No data');
          this.isHistoryTrack = false;
        } else {
          console.log('Error');
          this.isHistoryTrack = false;
        }
      });
    } else if ( value === '2' ) {
      this.historyService.getDeleteHistory().subscribe(data => {
        if (data?.status === 200) {
          this.data = data;
          this.isHistoryTrack = false;
        } else if (data?.status === undefined) {
          console.log('No data');
        } else {
          console.log('Error');
        }
      });
    }
  }

  public openTracking(): void {
    this.isOpen = !this.isOpen;
  }

}
