import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Content } from 'src/app/models/recommendations-response.model';
import { CarouselComponent } from 'src/app/shared/sliders/carousel/carousel.component';
import { PicturesGalleryModel } from './picture-gallery.model';

@Component({
  selector: 'app-pictures-gallery',
  templateUrl: './pictures-gallery.component.html',
  styleUrls: ['./pictures-gallery.component.scss']
})
export class PicturesGalleryComponent implements OnInit {
  @Input() data: PicturesGalleryModel;
  currentSlide = 0;
  recData: PicturesGalleryModel[] = [];
  nextArrowDisable=true;
  prevArrowDisable=true;
  public innerWidth: any;
  @ViewChild('slider') slider: CarouselComponent;
  
  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  _slideConfigSmall: any = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.05,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  d: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {}
  handleChange(e: any): void {
    this.currentSlide = e.currentSlide
    var d=this.data.pictures
    if(this.currentSlide===d.length - 1 ){
      this.nextArrowDisable=false
    }
    else if(this.currentSlide!=d.length - 1  ){
      this.nextArrowDisable=true
    }
    if(this.currentSlide===0 ){
      this.prevArrowDisable=false
    }
    else if(this.currentSlide!=0 ){
      this.prevArrowDisable=true
    }
  }
  handleChange2(e: any): void {
    this.currentSlide = e.currentSlide
    var d=this.data.pictures

    this.innerWidth = window.innerWidth

    if(this.currentSlide===this.data.pictures.length - 3 ){
      this.nextArrowDisable=false
    }
    if(this.innerWidth<=991){
      if(this.currentSlide===this.data.pictures.length - 2 ){
        this.nextArrowDisable=false
      }
      else if(this.currentSlide!=this.data.pictures.length - 2  ){
        this.nextArrowDisable=true
      }
    }
    if(this.innerWidth<=767){
      if(this.currentSlide===this.data.pictures.length - 1 ){
        this.nextArrowDisable=false
      }
      else if(this.currentSlide!=this.data.pictures.length - 1  ){
        this.nextArrowDisable=true
      }
    }
    else if(this.currentSlide!=this.data.pictures.length - 3  ){
      this.nextArrowDisable=true
    }
    if(this.currentSlide===0 ){
      this.prevArrowDisable=false
    }
    else if(this.currentSlide!=0 ){
      this.prevArrowDisable=true
    }
  }
  handleNavigate(direction: string): void {
    if (direction === 'NEXT') {
      this.slider.next();
    }
    if (direction === 'PREV') {
      this.slider.prev();
    }
  }
  transformYourHtml(htmlTextWithStyle: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
